import React, { useState } from 'react';
import { FormControl, Select, MenuItem, Typography } from '@mui/material';
import { Currencies, SettingTypes } from 'src/shared/enums';
import { useDispatch, useSelector } from 'react-redux';
import { GlobalActionTypes } from 'src/redux/globals';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import variables from 'src/styles/variables.scss';
import clientApi from 'src/client/client';
import { arrayToObject } from 'src/shared/utils';
import { useAuth } from 'src/hooks/useAuth';

const CurrencyPicker = ({ disabled }) => {
  const { user } = useAuth();
  const dispatch = useDispatch();
  const globalSettings = arrayToObject(useSelector(state => state.settings));
  const impersonatedSettings = arrayToObject(useSelector(state => state.impersonated_settings));
  const [currentCurrency, setCurrentCurrency] = useState(user.isAdmin && impersonatedSettings?.currentUser ? impersonatedSettings?.currency : globalSettings?.currency);

  const handleCurrencyChange = async (event) => {
    const newCurrency = event.target.value;
    if (!impersonatedSettings?.currentUser) {
      await clientApi().app.settings.set(SettingTypes.currency, newCurrency);
      dispatch({ type: GlobalActionTypes.USER_SETTINGS, payload: [{ "name": SettingTypes.currency, "value": newCurrency }] });
    } else {
      dispatch({ type: GlobalActionTypes.IMPERSONATED_USER_SETTINGS, payload: [{ "name": SettingTypes.currency, "value": newCurrency }] });
    }

    setCurrentCurrency(newCurrency); // Update local state to reflect the change immediately
  };

  // Custom rendering of the selected value
  const renderValue = (selectedValue) => {

    return (
      <Typography sx={{ fontSize: '12px', fontWeight: '600', width: '120px', height: '20px', lineHeight: '1.9' }}>
        <span style={{ fontWeight: '400', color: variables.lightGraySecondary }}>Display currency: </span>
        {Currencies[selectedValue]}
      </Typography>
    );
  };

  return (
    <FormControl
      sx={{
        opacity: '80%',
        '.MuiInputBase-root': {
          background: variables.darkGraySecondary,
          borderRadius: '4px',
          minWidth: '170px',
        }
      }}
    >
      <Select
        sx={{
          '.MuiInputBase-input': {
            display: 'flex',
            alignItems: 'center',
          },
          '.MuiSvgIcon-root': {
            color: 'primary.main',
          },
          '.MuiInputBase-input': {
            height: '20px',
            width: '120px',
          }
        }}
        disabled={disabled}
        size='small'
        id="currency-select"
        value={currentCurrency}
        onChange={handleCurrencyChange}
        renderValue={renderValue}
        IconComponent={KeyboardArrowDownIcon}
      >
        {Object.keys(Currencies).map((key) => (
          <MenuItem key={key} value={key}>{Currencies[key]}</MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default CurrencyPicker;