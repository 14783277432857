import { useState } from "react";
import { Box, Typography, CircularProgress, IconButton, TextField } from "@mui/material";
import variables from 'src/styles/variables.scss';
import { SaveAltRounded } from "@mui/icons-material";

export function ComingSoon() {
    return (
        <Box sx={{
            backgroundColor: '#25272F',
            height: '18px',
        }}>
            <Typography sx={{
                textTransform: 'uppercase',
                color: variables.lightGrayPrimary,
                fontSize: '10px',
                fontWeight: 600,
                p: '2px 4px'
            }}>
                Coming soon
            </Typography>
        </Box>
    )
}

export function EditableTypography({ title, loading, onSaveAsync }) {
    const [editMode, setEditMode] = useState(false);
    const [text, setText] = useState(title);
    const [error, setError] = useState(false);

    const handleSave = () => {
        if (text.length === 0)
            return setError(true);

        onSaveAsync(text).then(() => { setEditMode(false); setError(false); });
    }

    return (
        <>
            {
                !editMode && <>
                    <HoverTypography onClick={() => setEditMode(true)}>
                        {text}
                    </HoverTypography>
                </>
            }
            {
                editMode && <>
                    <TextField
                        onKeyDown={(ev) => { if (ev.key === "Enter") handleSave() }}
                        error={error}
                        value={text}
                        variant={"outlined"}
                        onChange={(ev) => setText(ev.currentTarget.value)}
                        size={"small"}
                        InputProps={{
                            style: {
                                fontSize: "18px"
                            },
                            endAdornment:
                                <>
                                    {
                                        !loading &&
                                        <IconButton onClick={handleSave}>
                                            <SaveAltRounded></SaveAltRounded>
                                        </IconButton>
                                    }
                                    {
                                        loading &&
                                        <CircularProgress size={20} sx={{ display: "inherit" }}></CircularProgress>
                                    }
                                </>
                        }}
                    >
                    </TextField>
                </>
            }

        </>
    )
}

const HoverTypography = ({ onClick, children }) => {
    const [state, setState] = useState({
        border: '2px solid transparent',
        borderRadius: '10px',
        borderOpacity: '0.5',
        padding: '0 10px',
        display: 'inline-block',
        transition: 'border 0.3s ease-in-out',
        cursor: 'pointer',
        fontSize: '18px'
    });

    const handleMouseEnter = () => {
        setState({ ...state, border: '1px solid' })
    };

    const handleMouseLeave = () => {
        setState({ ...state, border: '1px solid transparent' });
    };

    return (
        <Typography
            style={state}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            onClick={onClick}
        >
            {children}
        </Typography>
    );
};

export function formatDate(dateString) {
    const parts = dateString.split('T'); // Split the date and time
    const datePart = parts[0]; // '2024-07-04'
    const timePart = parts[1].slice(0, -1); // Remove the 'Z' and get '21:18:40'

    const date = new Date(datePart);
    const options = { day: 'numeric', month: 'long', year: 'numeric' };

    const formattedDate = date.toLocaleDateString('en-GB', options);
    const formattedTime = timePart.slice(0, 5);

    return `${formattedDate} @ ${formattedTime}`;
}