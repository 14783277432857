import React from 'react';
import { Container, Typography, } from '@mui/material';
import { ConnectorsList } from './components/ConnectorsList';
import variables from 'src/styles/variables.scss';

export default function ConnectorsPage() {

  return (
    <Container sx={{ maxWidth: "850px !important", height: "100%", display: "flex", flexFlow: "column", justifyContent: "space-between" }}>
      <ConnectorsList />
      <Typography sx={{ fontSize: '10px', color: variables.midGraySecondary, marginTop: '2rem' }}>
        Tamzen Technology Ltd. is registered by the Financial Conduct Authority (Firm Reference Number 1017899) as an agent of Salt Edge Limited.
        Salt Edge Limited is registered by the Financial Conduct Authority (Firm Reference Number: 822499) and provides you with regulated account information services under the Payment Services Regulations 2017.
      </Typography>
    </Container>
  );
}