class ApiError extends Error {
    constructor(code, message) {
      super(message);
      this.code = code;
    }
}

const ErrorCodes = {
    "error_invalid_setting_name" : { code:"error_invalid_setting_name" , message:  "Invalid setting name" },
    "error_retreiveing_login_link": { code:"error_retreiveing_login_link" , message:  "Unable to retrieve a login link" },
    "error_request_params" : { code:"error_request_params" , message:  "Error validating request parameters" },
    "error_connector_not_found" : { code:"error_connector_not_found" , message:  "Connector not found" },
    "error_retreiveing_access_token": { code:"error_retreiveing_access_token" , message:  "Unable to retrieve an access token" },
    "error_unauthorized": { code:"error_unauthorized" , message:  "Unauthorized request" },
    "error_retrieving_data": { code:"error_retrieving_data" , message:  "Error retrieving data" },
    "error_unknown": { code:"error_unknown" , message:  "Unknown error occured" },
    "error_creating_connector": { code:"error_creating_connector" , message:  "Unable to create a connector, please contact us" },
    "error_transaction_exists" : { code: "error_transaction_exists", message: "Transaction already exists" },
    "error_file_content" : { code: "error_file_content", message: "File content is empty" },
    "error_removing_connector": { code: "error_removing_connector", message: "Connection removal failed. Please try again in a few minutes" },
}

module.exports = {
  ApiError, 
  ErrorCodes
}