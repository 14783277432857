import { Box, Card, Typography } from "@mui/material";
import React, { useEffect } from "react";
import ReactApexChart from "react-apexcharts";
import { useChart } from "src/components/chart";
import { useDataProvider } from "src/hooks/useDataProvider";
import { DataFilterGranularities } from "src/shared/enums";
import variables from 'src/styles/variables.scss';
import 'src/styles/styles.scss';
import moment from "moment";
import 'moment/locale/en-gb';
import { useUserSettings } from "src/hooks/useUserSettings";
import { CashflowChartOptions } from "src/styles/widgetStyles";

export default function BurnRateChart() {
    const dataProvider = useDataProvider();
    const { isReady } = useUserSettings();

    useEffect(() => {
        const newFilter = {
            ...dataProvider.filter, path: `sets/burnRate`,
            granularity: DataFilterGranularities.weekly,
            start: moment().subtract(1, 'month').format('YYYY-MM-DD'),
            end: moment().add(3, 'month').format('YYYY-MM-DD'),
        };
        dataProvider.setFilter(newFilter);
    }, []);

    const currency = dataProvider.filter.currency.toUpperCase();

    const widgetData = dataProvider?.data ?? [];

    const actualSeries = widgetData?.actuals ?? [];
    const forecastSeries = widgetData?.forecast ?? [];

    const firstForecastDataPoint = forecastSeries[0] ?? 0;

    const burn = widgetData?.stats ?? {};

    const currentDate = moment();
    const yesterdayDate = moment().subtract(1, 'weeks');
    const todaysValue = burn[currentDate.format('YYYY-[W]WW')]?.burn ? -(burn[currentDate.format('YYYY-[W]WW')]?.burn) : 0;
    const yesterdaysValue = burn[yesterdayDate.format('YYYY-[W]WW')]?.burn ? -(burn[yesterdayDate.format('YYYY-[W]WW')]?.burn) : 0;
    const diff = todaysValue - yesterdaysValue;
    const percentageDiff = yesterdaysValue !== 0 ? ((diff / yesterdaysValue) * 100).toFixed(2) : 0;

    const convertPointToDate = (dateString) => {
        // Check if the date string is in the weekly format "YYYY-Www"
        if (/^\d{4}-W\d{1,2}$/.test(dateString)) {
            const [year, weekStr] = dateString.split('-W');
            // Use moment to convert week number to the first day of that week (ISO week by default starts on Monday)
            return moment().year(year).week(weekStr).startOf('isoWeek').format('YYYY-MM-DD');
        }

        return dateString;
    };

    //Extract the Last Actual Series Element
    const lastActualElement = actualSeries?.length > 0 ? {
        point: actualSeries[actualSeries?.length - 1].point,
        value: actualSeries[actualSeries?.length - 1].value,
    } : null;

    //Adjust the First Forecast Series Element if lastActualElement exists
    if (lastActualElement && forecastSeries?.length > 0) {
        const adjustedFirstForecastElement = {
            point: lastActualElement.point,
            value: {
                worst: lastActualElement.value,
                average: lastActualElement.value,
                best: lastActualElement.value,
            },
        };

        forecastSeries[0] = adjustedFirstForecastElement;
    }

    const chartSeries = [
        {
            name: 'Actual',
            type: 'line',
            data: actualSeries?.map(item => ({
                x: convertPointToDate(item.point),
                y: item.value,
            })) ?? [],
        },
        {
            name: 'Forecast Range',
            type: 'rangeArea',
            data: forecastSeries?.map(item => ({
                x: convertPointToDate(item.point),
                y: [item.value.worst, item.value.best],
            })) ?? [],
        },
        {
            name: 'Forecast Average',
            type: 'line',
            data: forecastSeries?.map(item => ({
                x: convertPointToDate(item.point),
                y: item.value.average,
            })) ?? [],
        }
    ];

    const chartOptions = useChart({
        ...CashflowChartOptions,
        xaxis: {
            type: 'datetime',
            labels: {
                rotate: 0,
                style: {
                    colors: variables.lightGrayPrimary,
                },
            },
            tooltip: {
                formatter: function (val) {
                    const date = new Date(val);
                    if (dataProvider.filter.granularity === DataFilterGranularities.weekly) {
                        return moment(date).format("[w] DD MMM 'YY");
                    } else {
                        return moment(date).format("MMM 'YY");
                    }
                }
            }
        },
        annotations: {
            xaxis: [
                {
                    x: new Date(convertPointToDate(firstForecastDataPoint.point)).getTime(),
                    strokeDashArray: 0,
                    borderColor: variables.greenBackground,
                    borderWidth: 2,
                    label: {
                        text: `FORECAST`,
                        orientation: 'horizontal',
                        textAnchor: 'start',
                        borderColor: variables.darkGraySecondary,
                        borderWidth: 0,
                        style: {
                            color: variables.lightGrayPrimary,
                            background: variables.darkGraySecondary,
                            cssClass: 'apexcharts-text',
                        },
                        offsetY: -15
                    },
                },
            ],
        },
        tooltip: {
            shared: false,
            intersect: false,
            theme: 'dark',
            custom: function ({ series, seriesIndex, dataPointIndex, w }) {
                // Assuming the second series is always the forecast line and the first series is the range area
                let rangeSeriesIndex = w.config.series.findIndex(s => s.name === 'Forecast Range');
                let forecastSeriesIndex = w.config.series.findIndex(s => s.name === 'Forecast Average');

                if (seriesIndex === forecastSeriesIndex) {
                    // If hovering over the forecast line, show data from both the line and range area
                    const forecastValue = series[forecastSeriesIndex][dataPointIndex];
                    const rangeValues = w.config.series[rangeSeriesIndex].data[dataPointIndex].y;
                    return (
                        '<div class="tooltip_box">' +
                        "<span>" +
                        `Forecast: ${currency} ${forecastValue.toLocaleString('en-US')}<br>` +
                        '<span class="tooltip_range">' +
                        `Range: ${currency} ${rangeValues[0].toLocaleString('en-US')} - ${currency} ${rangeValues[1].toLocaleString('en-US')}` +
                        "</span>" + "</span>" +
                        "</div>"
                    );
                } else {
                    // For other series, you can customize as needed
                    const data = series[seriesIndex][dataPointIndex];
                    return (
                        '<div class="tooltip_box">' +
                        "<span>" + `${currency} ${data.toLocaleString('en-US')}` + "</span>" +
                        "</div>"
                    );
                }
            },
        },
    });

    const [integerPart, decimalPart] = todaysValue.toLocaleString('us-US').split('.');

    return (
        <Card
            sx={{
                height: '320px',
                p: '1.5rem',
                boxShadow: 'none',
                background: variables.darkGraySecondary,
                borderRadius: '4px',
                position: 'relative',
                display: 'flex',
                flexFlow: 'column',
                justifyContent: 'space-between',
            }}
        >
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Box>
                    <Typography sx={{ fontSize: '12px', fontWeight: 600 }}>Burn Rate</Typography>
                    <Typography sx={{ fontSize: '24px', fontWeight: 600, display: 'flex', alignItems: 'baseline', lineHeight: 1 }}>
                        {currency + ' ' + integerPart}
                        {decimalPart &&
                            <span style={{ fontSize: '14px', fontWeight: 500 }}>.{decimalPart}</span>
                        }
                        <span
                            style={{
                                fontSize: '12px',
                                color: diff > 0 ? variables.greenPrimary : diff < 0 ? variables.redPrimary : variables.lightGrayPrimary,
                                display: 'flex',
                                alignItems: 'center'
                            }}
                        >
                            <img
                                src={diff < 0 ? "/assets/icons/ic_stats_arrow_down.svg" : "/assets/icons/ic_stats_arrow_up.svg"}
                                style={{ padding: '0 0.5rem 0 1rem' }}
                            />
                            {Math.abs(percentageDiff)}%
                            <span style={{ color: variables.lightGrayPrimary, fontWeight: 400, padding: '0 0.5rem' }}> vs. last week</span>
                        </span>
                    </Typography>
                </Box>
            </Box>
            <Box>
                {isReady() &&
                    <ReactApexChart
                        options={chartOptions}
                        series={chartSeries}
                        type="rangeArea"
                        height={177}
                    />
                }
            </Box>
        </Card >
    );
};
