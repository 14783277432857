// component
import SvgColor from '../../../components/svg-color';
import AddchartIcon from '@mui/icons-material/Addchart';

// ----------------------------------------------------------------------

const icon = (name) => <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1, minWidth: '30px', }} />;

const userNavConfig = [
  {
    title: 'Cashflow',
    path: '/overview',
    icon: icon('ic_wallet'),
    navClass: 'cashflow-btn',
  },
  // {
  //   title: 'Performance',
  //   path: '/performance-kpis',
  //   icon: icon('ic_kpi'),
  //   navClass: 'performance-btn',
  // },
  {
    title: 'Connectors',
    path: '/connectors',
    icon: <AddchartIcon></AddchartIcon>,
    navClass: 'connectors-btn',
  },
];

const adminNavConfig = [
  {
    title: 'User data',
    path: '/admin/user',
    icon: icon('ic_data'),
    navClass: 'user-data-btn',
  },
  {
    title: 'Cashflow',
    path: '/overview',
    icon: icon('ic_wallet'),
    navClass: 'cashflow-btn',
  },
  // {
  //   title: 'Performance',
  //   path: '/performance-kpis',
  //   icon: icon('ic_kpi'),
  //   navClass: 'performance-btn',
  // },
  {
    title: 'Connectors',
    path: '/connectors',
    icon: <AddchartIcon></AddchartIcon>,
    navClass: 'connectors-btn',
  },
];

export { adminNavConfig, userNavConfig };
