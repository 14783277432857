import { Box, Button, Card, Stack, Tooltip, Typography } from "@mui/material"

import variables from 'src/styles/variables.scss';
import { WidgetOverlayStyle, WidgetPreviewImage } from "../../../styles/widgetStyles";
import { DataFilterGranularities, WidgetTypes } from "src/shared/enums";
import { useDataProvider } from "src/hooks/useDataProvider";
import { useEffect } from "react";
import DataTable from "../../../components/DataTable";
import moment from "moment";
import 'moment/locale/en-gb';
import { BarPlot, ChartsGrid, ChartsReferenceLine, ChartsTooltip, ChartsXAxis, ChartsYAxis, LineHighlightPlot, MarkPlot, ResponsiveChartContainer, axisClasses, chartsGridClasses } from "@mui/x-charts";

export const TransactionsPreview = () => {
    return (
        <Stack sx={WidgetOverlayStyle(WidgetTypes.transactions)} justifyContent={"center"} alignItems={"center"}>
            <Tooltip title={
                <Typography>
                    This is the transactions widget including cash flow, cash outflow and cash burn
                </Typography>
            }>
                <Stack direction={"row"} sx={{ backgroundColor: variables.darkGraySecondary, padding: 2, border: "1px solid white" }} spacing={2} alignItems={"center"}>
                    <Typography variant="body">
                        Transactions widget
                    </Typography>
                </Stack>
            </Tooltip>
        </Stack>
    )
}

export const TransactionsSelector = ({ onClick }) => {
    const previewUrl = WidgetPreviewImage("transactions");

    return (
        <Stack justifyContent={"center"} alignItems={"center"} spacing={2}>
            <Box sx={{
                height: 100,
                width: 100,
                border: "1px solid #333333",
                borderRadius: 3,
                backgroundImage: `${previewUrl}`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat"
            }}>
            </Box>
            <Typography variant={"body2"}>Transactions Widget</Typography>
            <Button size="small" variant={"outlined"} onClick={onClick}>Select</Button>
        </Stack>
    )
}

export default function TransactionsWidget({ fullscreen = false }) {

    const dataProvider = useDataProvider();

    useEffect(() => {
        const newFilter = {
            ...dataProvider.filter, path: `sets/cashflow`,
            fullscreen: fullscreen,
            start: moment().subtract(12, 'week').startOf('week').format('YYYY-MM-DD'),
            end: dataProvider.filter.granularity === DataFilterGranularities.weekly ? moment().add(12, 'week').endOf('week').format('YYYY-MM-DD') : moment().add(2, 'month').endOf('month').format('YYYY-MM-DD'),
        };
        dataProvider.setFilter(newFilter);
    }, []);

    const { cashflowSet: widgetData } = dataProvider?.data ?? [];

    const incomeSeries = widgetData?.actualsIncome ?? [];
    const expenseSeries = widgetData?.actualsExpenses ?? [];
    const forecastIncomeSeries = widgetData?.forecastIncome ?? [];
    const forecastExpenseSeries = widgetData?.forecastExpenses ?? [];

    const actualsXAxis = incomeSeries?.map(item => {
        if (dataProvider.filter.granularity === DataFilterGranularities.weekly) {
            return moment(item?.point).format("[w]WW MMM DD");
        } else {
            return moment(item?.point).format("MMM 'YY");
        }
    });

    const forecastXAxis = forecastIncomeSeries?.map(item => {
        if (dataProvider.filter.granularity === DataFilterGranularities.weekly) {
            return moment(item?.point).format("[w]WW MMM DD");
        } else {
            return moment(item?.point).format("MMM 'YY");
        }
    });

    const combinedXAxis = actualsXAxis.concat(forecastXAxis);

    const incomeYAxis = incomeSeries?.map(item => item?.value?.toFixed(2) ?? 0);
    const expensesYAxis = expenseSeries?.map(item => item?.value?.toFixed(2) ?? 0);
    // Calculate the number of nulls to prepend
    const numNullsIncome = incomeYAxis.length;
    const numNullsExpenses = expensesYAxis.length;

    // Create arrays of nulls of the appropriate length
    const nullsForIncomeForecast = Array(numNullsIncome).fill(null);
    const nullsForExpensesForecast = Array(numNullsExpenses).fill(null);

    // Prepend nulls to the forecast arrays
    const forecastIncomeYAxis = [
        ...nullsForIncomeForecast,
        ...forecastIncomeSeries?.map(item => item?.value?.average?.toFixed(2) ?? 0)
    ];

    const forecastExpensesYAxis = [
        ...nullsForExpensesForecast,
        ...forecastExpenseSeries?.map(item => item?.value?.average?.toFixed(2) ?? 0)
    ];

    const config = {
        resolveSizeBeforeRender: true,
        xAxis: [
            {
                scaleType: 'band',
                data: combinedXAxis,
                valueFormatter: (month, context) => (
                    context.location === 'tick'
                        ? (dataProvider.filter.granularity === DataFilterGranularities.weekly ?
                            `${month.slice(0, 3)} \n${month.slice(4, 11)}` : `${month}`) : `${month}`
                ),
            }
        ],
        yAxis: [
            {
                tickMinStep: 5000,
                valueFormatter: (value) => {
                    let absValue = Math.abs(value);
                    let sign = value < 0 ? '-' : ''; // Check if the original value was negative
                    if (absValue < 1000) {
                        return sign + absValue.toFixed(2); // Reapply the negative sign if needed
                    } else if (absValue < 1000000) {
                        return sign + (absValue / 1000).toLocaleString('us-US', {
                            minimumFractionDigits: 1,
                            maximumFractionDigits: 1
                        }) + 'K';
                    } else {
                        return sign + `${(absValue / 1000000).toLocaleString('us-US', { minimumFractionDigits: 1, maximumFractionDigits: 1 })}M`;
                    }
                },
            }
        ],
        colors: ['rgba(190, 224, 55, 0.5)', variables.greenPrimary, variables.bluePrimary, variables.orangePrimary],
        grid: {
            horizontal: true,
        },
        series: [
            {
                type: 'bar',
                data: forecastIncomeYAxis,
                valueFormatter: (value) => (value == null ? 'NaN' : value.toString()),
                label: 'Forecast Income',
                stack: 'stack1'
            },
            {
                type: 'bar',
                data: forecastExpensesYAxis,
                valueFormatter: (value) => (value == null ? 'NaN' : value.toString()),
                label: 'Forecast Expenses',
                stack: 'stack2'
            },
            {
                type: 'bar',
                data: incomeYAxis,
                valueFormatter: (value) => (value == null ? 'NaN' : value.toString()),
                label: 'Income',
                stack: 'stack1'
            },
            {
                type: 'bar',
                data: expensesYAxis,
                valueFormatter: (value) => (value == null ? 'NaN' : value.toString()),
                label: 'Expenses',
                stack: 'stack2'
            },
        ],
        height: 385,
        sx: {
            [`& .${axisClasses.left} .${axisClasses.label}`]: {
                transform: 'translateX(-10px)',
            },
            [`& .${chartsGridClasses.line}`]: { strokeDasharray: '3 2', strokeWidth: 1 },
            '.MuiChartsAxis-tickLabel': {
                fill: variables.midGraySecondary + ' !important',
            },
        }
    }

    // const CustomItemTooltipContent = (props) => {
    //     const { itemData, series } = props;
    //     console.log(itemData)
    //     console.log(series)
    //     return (
    //         <Paper sx={{ p: 2 }}>
    //             {/* {series.map((item, index) => (
    //                 <Box sx={{ display: 'flex', alignItems: 'center', mb: '0.5rem', justifyContent: 'space-between' }}>
    //                     <Box sx={{ display: 'flex', alignItems: 'center' }}>
    //                         <Box sx={{ width: '12px', height: '12px', mx: '0.5rem', borderRadius: '50%', backgroundColor: item.color }}></Box>
    //                         <Typography sx={{ fontSize: '12px', fontWeight: 600, pr: '0.5rem' }}>{item.label}</Typography>
    //                     </Box>
    //                     <Typography sx={{ fontSize: '12px', fontWeight: 600, pr: '0.5rem' }}>{item.data[index]}</Typography>
    //                 </Box>
    //             ))} */}

    //             {/* <p>x: {series.data[itemData.dataIndex].x.toFixed(2)}</p>
    //         <p>y: {series.data[itemData.dataIndex].y.toFixed(2)}</p>
    //         <p>
    //           additional value: {series.data[itemData.dataIndex].other.toFixed(2)}
    //         </p>
    //         <p>
    //           other additional value:{" "}
    //           {series.data[itemData.dataIndex].other2.toFixed(2)}
    //         </p> */}
    //         </Paper>
    //     );
    // };

    return (
        <>
            <Card sx={{ p: '1.5rem', boxShadow: 'none', background: variables.darkGrayPrimary, border: '1px solid ' + variables.midGrayPrimary, borderRadius: '4px' }}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'start' }}>
                    <Typography sx={{ fontSize: '24px', fontWeight: 600 }}>Transactions</Typography>
                </Box>
                <Box dir="ltr">
                    <ResponsiveChartContainer {...config}>
                        <BarPlot />
                        <ChartsReferenceLine
                            x={forecastXAxis[0] ?? null}
                            lineStyle={{ strokeDasharray: '3 5' }}
                            labelStyle={{ fontSize: '14' }}
                            label={`Forecast`}
                            labelAlign="start"
                        />
                        <ChartsXAxis disableLine disableTicks />
                        <ChartsYAxis disableLine disableTicks />
                        <MarkPlot />
                        <ChartsGrid horizontal={true}
                            classes={{
                                root: {
                                    display: 'none'
                                },
                                line: {
                                    strokeDasharray: '3 2',
                                    strokeWidth: 1
                                }
                            }} />
                        <LineHighlightPlot />
                        <ChartsTooltip trigger="item" />
                    </ResponsiveChartContainer>
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'start', alignItems: 'center', pt: '1rem' }}>
                    <Box sx={{ width: '15px', height: '15px', mx: '0.5rem', backgroundColor: variables.bluePrimary }}></Box>
                    <Typography sx={{ fontSize: '12px', fontWeight: 600, pr: '0.5rem' }}>Income</Typography>
                    <Box sx={{ width: '15px', height: '15px', mx: '0.5rem', backgroundColor: variables.orangePrimary }}></Box>
                    <Typography sx={{ fontSize: '12px', fontWeight: 600, pr: '0.5rem' }}>Expenses</Typography>
                    <Box sx={{ width: '15px', height: '15px', mx: '0.5rem', backgroundColor: 'rgba(190, 224, 55, 0.5)' }}></Box>
                    <Typography sx={{ fontSize: '12px', fontWeight: 600, pr: '0.5rem' }}>Forecast Income</Typography>
                    <Box sx={{ width: '15px', height: '15px', mx: '0.5rem', backgroundColor: variables.greenPrimary }}></Box>
                    <Typography sx={{ fontSize: '12px', fontWeight: 600, pr: '0.5rem' }}>Forecast Expenses</Typography>
                </Box>
            </Card>
            {fullscreen &&
                <Box sx={{ mt: 5 }}>
                    <Typography sx={{ fontSize: '18px', fontWeight: 600, px: 3, py: 1 }}>Transactions Detailed View</Typography>
                    <DataTable
                        dataProvider={dataProvider}
                        type={'transactions'}
                        editData={true}
                        loadMoreData={false}
                    >
                    </DataTable>
                </Box>
            }
        </>
    );
}
