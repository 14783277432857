import React from 'react';

import { Authenticator, Image, Text, View, useTheme } from '@aws-amplify/ui-react';
import Router, { PublicRoutes } from 'src/routes';
import Logo from './logo/Logo';

import 'src/styles/AuthStyles.scss';
import { UserSettingsProvider } from 'src/hooks/useUserSettings';
import { SettingTypes } from 'src/shared/enums';
import { useAuth } from 'src/hooks/useAuth';
import LoadingPage from 'src/pages/LoadingPage';
import { useLocation } from 'react-router-dom';
import { Divider } from '@mui/material';
import Cookies from 'js-cookie';

const welcomeMessage = Cookies.get('eleni.welcome');

const components = {
  Header() {
    const { tokens } = useTheme();

    return (
      <View textAlign="center" padding={tokens.space.large}>
        <Image
          alt="Eleni Finance logo"
          src="/assets/icons/navbar/ic_logo_lg.svg"
          height={'50px'}
        />
        <Text color={tokens.colors.white} padding={"3rem 0 0.5rem"} fontSize={"20px"}>
          {!welcomeMessage ? 'Welcome to Eleni!' : 'Welcome back!'}
        </Text>
      </View>
    );
  },

  Footer() {
    const { tokens } = useTheme();

    return (
      <>
        {!welcomeMessage &&
          <View textAlign="center" padding={tokens.space.large}>
            <Divider sx={{ width: "180px", margin: "0 auto 3rem" }} />

            <Text color={tokens.colors.neutral[80]}>
              By signing in you agree to the <a href='/terms-and-privacy#terms-of-service' style={{ color: tokens.colors.neutral[80] }}>Terms of Service</a> and <a href='/terms-and-privacy#privacy-policy' style={{ color: tokens.colors.neutral[80] }}>Privacy Policy</a>
            </Text>
          </View>
        }
      </>
    );
  },
}

const formFields = {
  signUp: {
    'custom:company_name': {
      label: 'Company name',
      placeholder: 'Enter your company name',
      isRequired: false,
      order: 1,
    }
  },
};

function AuthWrapper() {
  const location = useLocation();

  // List of public routes that don't require AuthWrapper
  const publicPaths = ['/terms-and-privacy'];

  // Check if the current route matches any of the public routes
  const isPublicRoute = publicPaths.includes(location.pathname);

  return (
    <>
      {/* Render public routes */}
      <PublicRoutes />

      {/* Conditionally render Authenticator only for non-public routes */}
      {!isPublicRoute &&
        <Authenticator
          loginMechanisms={['email']}
          socialProviders={[]}
          components={components}
          formFields={formFields}
          hideSignUp
        >
          <UserSettingsProvider keys={[SettingTypes.currency, SettingTypes.environment, SettingTypes.profile, SettingTypes.first_auth, SettingTypes.company_info]}>
            <Router />
          </UserSettingsProvider>
        </Authenticator>
      }
    </>
  );
}

export default AuthWrapper;
