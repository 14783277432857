
import BurnRateChart from "./BurnRateChart";
import CashBalanceChart from "./CashBalanceChart";
import CashInCard from "./CashInCard";
import CashOutCard from "./CashOutCard";
import ForecastWidget, { ForecastPreview, ForecastSelector } from "./Forecast";
import TransactionsWidget, { TransactionsPreview, TransactionsSelector } from "./Transactions";

const { WidgetTypes, DataFilterGranularities, DataProviderFilter } = require("src/shared/enums");

const widgetMapping = {};

widgetMapping[WidgetTypes.cash_balance] = {
    widget: CashBalanceChart,
    config: {
        allowFullscreen: true,
        path: 'cash_balance',
        enabledFilters: {
            [DataProviderFilter.granularity]: { options: [DataFilterGranularities.monthly, DataFilterGranularities.weekly] },
            // [DataProviderFilter.periodRange]: {},
        }
    },
};

widgetMapping[WidgetTypes.burn_rate] = {
    widget: BurnRateChart,
    config: {
        allowFullscreen: false,
        path: 'burn_rate',
        enabledFilters: {
            [DataProviderFilter.granularity]: { options: [DataFilterGranularities.monthly, DataFilterGranularities.weekly] },
            // [DataProviderFilter.periodRange]: {},
        }
    },
};

widgetMapping[WidgetTypes.cashin_card] = {
    widget: CashInCard,
    config: {
        path: 'cashin_card',
        allowFullscreen: false,
        enabledFilters: { }
    },
};

widgetMapping[WidgetTypes.cashout_card] = {
    widget: CashOutCard,
    config: {
        path: 'cashout_card',
        allowFullscreen: false,
        enabledFilters: { }
    },
};

widgetMapping[WidgetTypes.transactions] = {
    preview: TransactionsPreview,
    selector: TransactionsSelector,
    widget: TransactionsWidget,
    config: {
        allowFullscreen: true,
        path: 'transactions',
        enabledFilters: {
            // [DataProviderFilter.periodRange]: {},
            [DataProviderFilter.granularity]: {
                options: [DataFilterGranularities.monthly, DataFilterGranularities.weekly]
            }
        }
    },
};

widgetMapping[WidgetTypes.forecast] = {
    preview: ForecastPreview,
    selector: ForecastSelector,
    widget: ForecastWidget,
    config: {
        allowFullscreen: true,
        path: 'forecast',
        enabledFilters: {
            [DataProviderFilter.granularity]: {
                options: [DataFilterGranularities.monthly, DataFilterGranularities.weekly]
            },
            // [DataProviderFilter.periodRange]: {},
        }
    }
};

export const WidgetMapping = widgetMapping;