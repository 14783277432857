import { Box, Stack, Tooltip, Typography, Button, Card } from "@mui/material"

import variables from 'src/styles/variables.scss';
import { WidgetChartOptions, WidgetOverlayStyle, WidgetPreviewImage } from "src/styles/widgetStyles";
import { DataFilterGranularities, WidgetTypes } from "src/shared/enums";
import { useDataProvider } from "src/hooks/useDataProvider";
import { useEffect } from "react";
import moment from "moment";
import 'moment/locale/en-gb';
import { LineChart, axisClasses, chartsGridClasses } from "@mui/x-charts";
import { useChart } from "src/components/chart";
import DataTable from "src/components/DataTable";


export const ForecastPreview = () => {
    return (
        <Stack sx={WidgetOverlayStyle(WidgetTypes.forecast)} justifyContent={"center"} alignItems={"center"}>
            <Tooltip title={
                <Typography>
                    This is the cash forecast widget including cash flow, cash outflow and cash burn
                </Typography>
            }>
                <Stack direction={"row"} sx={{ backgroundColor: variables.darkGraySecondary, padding: 2, border: "1px solid white" }} spacing={2} alignItems={"center"}>
                    <Typography variant="body">
                        Forecast widget
                    </Typography>
                </Stack>
            </Tooltip>
        </Stack>
    )
}

export const ForecastSelector = ({ onClick }) => {
    const previewUrl = WidgetPreviewImage("forecast");

    return (
        <Stack justifyContent={"center"} alignItems={"center"} spacing={2}>
            <Box sx={{
                height: 100,
                width: 100,
                border: "1px solid #333333",
                borderRadius: 3,
                backgroundImage: `${previewUrl}`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat"
            }}>
            </Box>
            <Typography variant={"body2"}>Forecast widget</Typography>
            <Button size="small" variant={"outlined"} onClick={onClick}>Select</Button>
        </Stack>
    )
}

export default function ForecastWidget({ fullscreen = false }) {

    const dataProvider = useDataProvider();

    useEffect(() => {
        const newFilter = {
            ...dataProvider.filter, path: `sets/cashflow`,
            fullscreen: fullscreen,
            start: moment().subtract(12, 'week').startOf('week').format('YYYY-MM-DD'),
            end: dataProvider.filter.granularity === DataFilterGranularities.weekly ? moment().add(12, 'week').endOf('week').format('YYYY-MM-DD') : moment().add(2, 'month').endOf('month').format('YYYY-MM-DD'),
        };
        dataProvider.setFilter(newFilter);
    }, []);

    const currency = dataProvider.filter.currency.toUpperCase();

    const { cashflowSet: widgetData } = dataProvider?.data ?? [];

    const netCashflowSeries = widgetData?.forecastNetCashflow ?? [];

    const convertPointToDate = (dateString) => {
        // Check if the date string is in the weekly format "YYYY-Www"
        if (/^\d{4}-W\d{1,2}$/.test(dateString)) {
            const [year, weekStr] = dateString.split('-W');
            // Use moment to convert week number to the first day of that week (ISO week by default starts on Monday)
            return moment().year(year).week(weekStr).startOf('isoWeek').format('YYYY-MM-DD');
        }

        return dateString;
    };

    const forecastXAxis = netCashflowSeries?.map(item => {
        if (dataProvider.filter.granularity === DataFilterGranularities.weekly) {
            return moment(item?.point).format("[w]WW MMM DD");
        } else {
            return moment(item?.point).format("MMM 'YY");
        }
    });

    const forecastYAxis = netCashflowSeries?.map(item => item?.value?.average.toFixed(2));

    // Prepare chart data using forecastSet
    const chartSeries = [
        {
            name: 'Forecast Range',
            type: 'rangeArea',
            data: netCashflowSeries.map(i => ({
                x: convertPointToDate(i.point),
                y: [Number(parseFloat(i.value["worst"]).toFixed(2)), Number(parseFloat(i.value["best"]).toFixed(2))],
            })) ?? [],
        },
        {
            name: 'Forecast Average',
            type: 'line',
            fill: 'solid',
            strokeWidth: 4,
            data: netCashflowSeries.map(i => ({
                x: convertPointToDate(i.point),
                y: Number(parseFloat(i.value["average"]).toFixed(2)),
            })) ?? [],
        }
    ];

    const chartOptions = useChart({
        ...WidgetChartOptions,
        xaxis: {
            type: 'datetime',
            labels: {
                rotate: 0,
                style: {
                    colors: variables.lightGrayPrimary,
                },
            },
            tooltip: {
                formatter: function (val) {
                    const date = new Date(val);
                    if (dataProvider.filter.granularity === DataFilterGranularities.weekly) {
                        return moment(date).format("[w] DD MMM 'YY");
                    } else {
                        return moment(date).format("MMM 'YY");
                    }
                },
                style: {
                    fontSize: '16px', // This style doesn't apply directly via options; use CSS for tooltips
                }
            }
        },
        colors: [variables.greenBackground, variables.greenPrimary],
        legend: {
            show: false,
        },
        markers: {
            shape: "circle",
            size: [5, 5],
            colors: variables.darkGraySecondary,
            strokeWidth: 3,
            strokeColors: [variables.greenPrimary, variables.greenBackground],
            strokeOpacity: 1,
            fillOpacity: 1, // 0 makes the inside of the marker transparent
            hover: {
                strokeWidth: 4,
                size: 7,
            }
        },
        tooltip: {
            shared: true,
            intersect: false,
            theme: 'dark',
            custom: function ({ series, seriesIndex, dataPointIndex, w }) {
                let rangeSeriesIndex = w.config.series.findIndex(s => s.name === 'Forecast Range');
                let forecastSeriesIndex = w.config.series.findIndex(s => s.name === 'Forecast Average');

                const forecastValue = series[forecastSeriesIndex][dataPointIndex];
                const rangeValues = w.config.series[rangeSeriesIndex].data[dataPointIndex].y;
                return (
                    '<div class="tooltip_box">' +
                    "<span>" +
                    `Forecast: ${currency} ${forecastValue.toLocaleString('en-US')}<br>` +
                    '<span class="tooltip_range">' +
                    `Range: ${currency} ${rangeValues[0].toLocaleString('en-US')} - ${currency} ${rangeValues[1].toLocaleString('en-US')}` +
                    "</span>" + "</span>" +
                    "</div>"
                );
            },
        },
    });

    return (
        <>
            <Card sx={{ p: '1.5rem', boxShadow: 'none', background: variables.darkGrayPrimary, border: '1px solid ' + variables.midGrayPrimary, borderRadius: '4px' }}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'start' }}>
                    <Typography sx={{ fontSize: '24px', fontWeight: 600, display: 'flex' }}>
                        Net Cash Forecast
                        <img src="/assets/icons/ic_forecast.svg" alt="forecast icon" style={{ paddingLeft: '0.5rem' }} />
                    </Typography>
                </Box>
                <Box dir="ltr">
                    <LineChart
                        tooltip={{ trigger: 'axis' }}
                        xAxis={[
                            {
                                scaleType: 'point',
                                data: forecastXAxis,
                                valueFormatter: (month, context) =>
                                    context.location === 'tick'
                                        ? (dataProvider.filter.granularity === DataFilterGranularities.weekly ?
                                            `${month.slice(0, 3)} \n${month.slice(4, 11)}` : `${month}`) : `${month}`
                            },
                        ]}
                        yAxis={[
                            {
                                tickMinStep: 3000,
                                valueFormatter: (value) => {
                                    let absValue = Math.abs(value);
                                    let sign = value < 0 ? '-' : ''; // Check if the original value was negative
                                    if (absValue < 1000) {
                                        return sign + absValue.toFixed(2); // Reapply the negative sign if needed
                                    } else if (absValue < 1000000) {
                                        return sign + (absValue / 1000).toLocaleString('us-US', {
                                            minimumFractionDigits: 1,
                                            maximumFractionDigits: 1
                                        }) + 'K';
                                    } else {
                                        return sign + `${(absValue / 1000000).toLocaleString('us-US', { minimumFractionDigits: 1, maximumFractionDigits: 1 })}M`;
                                    }
                                },
                            },
                        ]}
                        colors={[variables.greenPrimary, variables.greenPrimary]}
                        grid={{
                            vertical: true,
                        }}
                        series={[
                            {
                                data: forecastYAxis,
                                valueFormatter: (value) => (value == null ? 'NaN' : value.toString()),
                            },
                        ]}
                        height={385}
                        sx={{
                            [`& .${axisClasses.left} .${axisClasses.label}`]: {
                                transform: 'translateX(-10px)',
                            },
                            [`& .${chartsGridClasses.verticalLine}`]: { strokeDasharray: '4', stroke: variables.greenPrimary, opacity: 0.3 },
                            '.MuiChartsAxis-tickLabel': {
                                fill: variables.midGraySecondary + ' !important',
                            },
                            '.MuiChartsAxis-line': {
                                display: 'none',
                            },
                            '.MuiChartsAxis-tick': {
                                display: 'none',
                            }
                        }}
                    />
                </Box>
            </Card>
            {fullscreen &&
                <Box sx={{ mt: 5 }}>
                    <Typography sx={{ fontSize: '18px', fontWeight: 600, px: 3, py: 1 }}>Transactions Detailed View</Typography>
                    <DataTable
                        dataProvider={dataProvider}
                        type={'transactions'}
                        editData={false}
                        loadMoreData={false}
                    >
                    </DataTable>
                </Box>
            }
        </>
    );
}           
