import React, { useEffect, useState } from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Button, Card, Checkbox, Dialog, DialogActions, DialogContent, FormControl, FormControlLabel, Grid, IconButton, LinearProgress, Radio, RadioGroup, Skeleton, Stack, styled, TextField, Tooltip, Typography, useTheme } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import clientApi from 'src/client/client';

import { ConnectorTypes } from 'src/shared/enums';

import { ErrorCodes } from 'src/shared/errors';
import { LoadingButton } from '@mui/lab';
import AppDrawer from 'src/components/AppDrawer';
import { AddCircleOutline } from '@mui/icons-material';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Box } from '@mui/system';
import { useSnackbar } from 'src/hooks/useSnackbar';
import { arrayToObject, formatAmount } from 'src/shared/utils';
import { useSelector } from 'react-redux';
import { useAuth } from 'src/hooks/useAuth';
import variables from 'src/styles/variables.scss';
import { EditableTypography } from 'src/utils/utils';

const mappingIcons = {
    [ConnectorTypes.SALTEDGE_PARTNERS_V1]: "/assets/icons/ic_bank.png",
    [ConnectorTypes.SALTEDGE_V6]: "/assets/icons/ic_bank.png",
    [ConnectorTypes.GOOGLE_SHEET]: "/assets/icons/ic_google.png",
    [ConnectorTypes.STRIPE]: "/assets/icons/ic_stripe.svg",
    [ConnectorTypes.MONDAY]: "/assets/icons/ic_monday.png",
    [ConnectorTypes.DEMO]: "/assets/icons/ic_demo.png",
}

export function ConnectorsList() {
    const [connectors, setConnectors] = useState(null);
    const settings = arrayToObject(useSelector(state => state.impersonated_settings));
    const userId = settings.currentUser?.id ?? null;

    const fetchData = async () => {
        setConnectors(null);
        const params = userId ? { user: userId } : {}; // Add 'user' to params only if it's not null
        const connectors = await clientApi().app.connectors.list(params).catch((err) => { });
        setConnectors(connectors)
    }

    useEffect(() => {
        fetchData();
    }, []);

    console.log(connectors)

    return (
        <Box>
            {connectors?.length > 0 &&
                <Stack direction={"row"} justifyContent={"space-between"}>
                    <Typography variant="h4" gutterBottom>
                        Connected data sources
                    </Typography>
                    <ImportConnector onAdded={fetchData} />
                </Stack>
            }
            <Stack spacing={3} mt={2}>
                {connectors == null &&
                    <ConnectorsListSkeleton />
                }
                {connectors?.length == 0 &&
                    <Stack spacing={3} alignItems={"center"}>
                        <Typography variant={"body"}>No connectors found yet.</Typography>
                        <Box>
                            <ImportConnector
                                onAdded={fetchData}
                                linkText="Create your first connection"
                            />
                        </Box>
                    </Stack>
                }
                {connectors?.length > 0 &&
                    <>
                        {connectors.map((connector) => {
                            return (
                                <ConnectorItem key={"connector_" + connector.id} connector={connector} onRemoved={fetchData}></ConnectorItem>
                            );
                        })
                        }
                    </>
                }
            </Stack>
        </Box>
    );
}

function ConnectorsListSkeleton() {
    return (
        <Stack spacing={0} mt={2}>
            <Skeleton></Skeleton>
            <Skeleton></Skeleton>
            <Skeleton></Skeleton>
        </Stack>
    )
}

export function ConnectorItem({ connector, onRemoved }) {
    const { openError } = useSnackbar();
    const [loading, setLoading] = useState(false);

    console.log(connector)

    const handleOnTitleChange = async (title) => {
        setLoading(true);
        const _connector = await clientApi().app.connectors.update(connector.id, { title });
        setLoading(false);
    }

    const handleReconnect = async () => {
        setLoading(true);
        const result = await clientApi().app.connectors.reconnect(connector.id, { customerId: connector?.config?.customerId, connectionId: connector?.config?.connectionId });
        const url = result?.link ?? null;

        if (!url)
            return openError(ErrorCodes.error_retreiveing_login_link.code);

        window.open(url, '_blank');
        setLoading(false);
    }

    const getDaysLeftConsent = () => {
        if (!connector?.consentInfo?.data) {
            return 'Consent info unavailable';
        }

        if (connector.consentInfo.data.status !== 'active') {
            return 'Consent not granted';
        }

        if (!connector.consentInfo.data.expires_at) {
            return 'Consent valid until revoked';
        }

        const expiresAt = new Date(connector.consentInfo.data.expires_at);
        const today = new Date();
        const diffTime = expiresAt - today;
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

        return diffDays >= 0 ? `Consent expires in ${diffDays} days` : 'Consent expired';
    }

    return (
        <Stack
            direction={"column"}
            alignItems={"center"}
            justifyContent={"space-between"}
            spacing={2}
            sx={{
                backgroundColor: variables.darkGraySecondary,
                p: '1.5rem',
                borderRadius: '0.5rem',
                border: '1px solid ' + variables.midGrayPrimary,
                minHeight: '175px',
            }}>
            <Stack sx={{ width: "100%" }} direction={"row"} alignItems={"start"} justifyContent={"space-between"}>
                <Stack sx={{ width: "100%" }} direction={"row"} alignItems={"center"} spacing={2} justifyContent={"start"}>
                    <Tooltip title={connector.type.replace("_", " ") + ' connector'} >
                        <img src={mappingIcons[connector.type]} width={50} height={50} style={{ cursor: 'pointer' }} />
                    </Tooltip>
                    <Stack direction={"column"} alignItems={"start"} justifyContent={"start"}>
                        <EditableTypography
                            title={connector.name}
                            loading={loading}
                            onSaveAsync={handleOnTitleChange}
                        />
                        {connector?.connectionInfo?.data?.provider_name &&
                            <Typography sx={{ fontSize: '12px', p: "0 10px" }}>
                                {'Provider: ' + connector?.connectionInfo?.data?.provider_name + ' Bank'}
                            </Typography>
                        }
                    </Stack>
                </Stack>
                <Stack sx={{ width: "100%" }} direction={"column"} alignItems={"end"} spacing={2} justifyContent={"end"}>
                    {connector?.connectionInfo?.data?.status &&
                        <Typography sx={{
                            textTransform: 'uppercase',
                            color: (connector?.connectionInfo?.data?.status === 'active' && connector?.consentInfo?.data?.status === 'active') ? variables.greenPrimary : variables.redPrimary,
                            fontSize: '14px',
                            fontWeight: 600
                        }}
                        >
                            {(connector?.connectionInfo?.data?.status === 'active' && connector?.consentInfo?.data?.status === 'active') ? "ACTIVE" : "INACTIVE"}
                        </Typography>
                    }
                    {connector?.connectionInfo?.data?.last_attempt &&
                        <Stack direction={"column"}>
                            <Typography sx={{ m: '0 !important', fontSize: '12px' }}>
                                Last sync: {formatDate(connector.connectionInfo.data.last_attempt.updated_at)}
                            </Typography>
                            {connector?.connectionInfo?.data?.status === 'active' &&
                                <span style={{ color: variables.midGraySecondary, fontSize: '11px', textAlign: 'right' }}>
                                    {getDaysLeftConsent()}
                                </span>
                            }
                        </Stack>
                    }
                </Stack>
            </Stack>
            <Stack sx={{ width: "100%" }} direction={"row"} alignItems={"end"} spacing={2} justifyContent={"end"}>
                {connector?.accountId?.data &&
                    <Stack sx={{ width: "100%" }} direction={"column"}>
                        <Typography sx={{ width: '100%', fontSize: '12px', color: variables.midGraySecondary }}>
                            {connector?.accountId?.data?.length + (connector?.accountId?.data?.length > 1 ? ' accounts' : ' account') + ' shared'}
                        </Typography>
                        {connector?.accountId?.data?.map((account, index) => (
                            <Typography key={index} sx={{ fontSize: '12px' }}>
                                {account?.extra?.iban ? ('**** ' + account.extra.iban.slice(-4)) : account?.name} - Balance: <b>{account?.currency_code + ' ' + formatAmount(account.balance)}</b>
                            </Typography>
                        ))
                        }
                    </Stack>
                }
                {(connector?.connectionInfo?.data?.status !== 'active' || connector?.consentInfo?.data?.status !== 'active') &&
                    <>
                        <LoadingButton
                            loading={loading}
                            variant='contained'
                            onClick={handleReconnect}
                            sx={{
                                width: '120px',
                                boxShadow: 'none',
                                color: 'black',
                                borderRadius: '4px',
                                '&:hover': {
                                    backgroundColor: variables.midGrayPrimary,
                                    color: variables.lightGrayPrimary,
                                }
                            }}
                        >
                            Reconnect
                        </LoadingButton>
                    </>
                }
                <RemoveConnectorDrawer connector={connector} onRemoved={onRemoved} />
            </Stack>
        </Stack>
    )
}

function RemoveConnectorDrawer({ onRemoved, onClose, connector }) {
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [inputValue, setInputValue] = useState('');
    const [error, setError] = useState('');
    const snackbar = useSnackbar();
    let accountIds = [];

    if (connector.type === "demo") {
        accountIds = [...new Set(connector.accountId.data.map(account => account.id))];
    }
    console.log(connector);

    const handleRemove = async () => {
        if (inputValue !== connector.name) {
            setError('Connector name does not match.');
            return;
        }

        setLoading(true);
        await clientApi().app.connectors.remove(connector.id).catch((err) => { }).finally(() => { setLoading(false); snackbar.openError(ErrorCodes.error_removing_connector.code); });

        setOpen(false);
        snackbar.openSuccess('Connection removed successfully');
        onRemoved && onRemoved();
    };

    return (
        <>
            <Tooltip title="Remove connection">
                <DeleteOutlineIcon
                    onClick={() => setOpen(true)}
                    sx={{
                        ':hover': {
                            color: variables.redPrimary,
                            cursor: 'pointer',
                        },
                        marginBottom: '6px !important',
                    }}
                ></DeleteOutlineIcon>
            </Tooltip>
            <AppDrawer open={open} onClose={() => setOpen(false)} title={"Are you sure you want to remove this connection?"} height={40}>
                <Stack spacing={4}>
                    <Typography>
                        <b>Warning:</b> All data received through this connection will be permanently deleted. <b>This action is irreversible.</b> To remove the connector and all data associated with it you must type the connector's name in the box below.
                    </Typography>
                    <TextField
                        fullWidth
                        placeholder="Type the connector's name to confirm"
                        value={inputValue}
                        onChange={(e) => {
                            setInputValue(e.target.value);
                            setError('');
                        }}
                        error={!!error}
                        helperText={error}
                        sx={{
                            '&.MuiFormControl-root': {
                                '.MuiInputBase-root': {
                                    height: '48px',
                                    'fieldset': {
                                        borderColor: variables.midGrayPrimary
                                    }
                                }
                            }
                        }}
                    />
                    <Box>
                        <LoadingButton
                            loading={loading}
                            variant='contained'
                            onClick={handleRemove}
                            size='large'
                            sx={{ background: variables.redGradient, boxShadow: 'none', '&:hover': { backgroundColor: variables.redPrimary } }}
                        >
                            Remove connection permanently
                        </LoadingButton>
                    </Box>
                </Stack>
            </AppDrawer>
        </>
    );
}

function ImportConnector({ onAdded, linkText }) {
    const [open, setOpen] = useState(false);

    return (
        <>
            {!linkText &&
                <Button variant="outlined" sx={{ color: variables.lightGrayPrimary, border: '1px solid ' + variables.midGrayPrimary }} onClick={() => setOpen(true)}>
                    New connection <AddCircleOutline sx={{ color: variables.bluePrimary, ml: '0.5rem' }}></AddCircleOutline>
                </Button>
            }
            {linkText &&
                <LoadingButton variant="outlined" onClick={() => setOpen(true)}>Add a link to the bank</LoadingButton>
            }
            <Dialog
                open={open}
                onClose={() => setOpen(false)} t
                aria-labelledby="form-dialog-title"
                maxWidth="lg"
                fullWidth
                sx={{
                    '.MuiPaper-root': {
                        width: 'fit-content',
                        backgroundColor: variables.darkGraySecondary,
                    }
                }}
            >
                <DialogActions>
                    <IconButton onClick={() => setOpen(false)}><CloseIcon /></IconButton>
                </DialogActions>
                <DialogContent sx={{ pt: 0 }}>
                    <ImportConnectorDrawer onAdded={onAdded} />
                </DialogContent>
            </Dialog>

        </>
    );
}

function ImportConnectorDrawer({ onAdded }) {
    const { user } = useAuth();
    const impersonatedUser = arrayToObject(useSelector(state => state.impersonated_settings));
    const userId = user?.attributes?.sub;
    const impersonatedUserId = impersonatedUser.currentUser?.id ?? null;
    const [loading, setLoading] = useState(false);
    const { openError } = useSnackbar();
    const [openDemo, setOpenDemo] = useState(false);

    const [state, setState] = useState({
        step: 0,
        type: "",
        name: "",
        saltedge_allow: "both",
        stripe_allow: "forecast",
        publishableKey: "",
        boardId: "",
        apiToken: "",
    });

    const handleSubmit = async () => {
        setLoading(true);
        const params = impersonatedUserId ? { user: impersonatedUserId } : {};
        const connector = await clientApi().app.connectors.create(params, state).catch((err) => {
            openError(ErrorCodes.error_creating_connector.code);
            setLoading(false);
            return;
        });

        // Check if the connector type is SaltEdge and only then connect
        if (connector && (state.type === ConnectorTypes.SALTEDGE_PARTNERS_V1 || state.type === ConnectorTypes.SALTEDGE_V6)) {
            await handleSaltedgeConnect(connector?.id);
        }

        if (connector && (state.type === ConnectorTypes.STRIPE || state.type === ConnectorTypes.DEMO)) {
            await handleSyncConnection(connector?.id);
        }

        setLoading(false);
        onAdded && onAdded();
    };

    const handleSyncConnection = async (connectorId) => {
        setLoading(true);
        await clientApi().app.connectors.sync(connectorId).catch((err) => { }).finally(() => setLoading(false));
    }

    const handleSaltedgeConnect = async (connectorId) => {
        setLoading(true);

        const config = await clientApi().app.connectors.init(connectorId).catch((err) => { }).finally(() => setLoading(false));
        const url = config?.link ?? null;

        if (!url)
            return openError(ErrorCodes.error_retreiveing_login_link.code);

        window.open(url, '_blank');
    };

    const isNextAllowed = () => {
        if (state.step == 0 && state.type != "") {
            return true
        }
        if (state.step == 1 && (((state.type === ConnectorTypes.SALTEDGE_PARTNERS_V1 || state.type === ConnectorTypes.SALTEDGE_V6 || ConnectorTypes.DEMO) && state.name !== "") ||
            (state.type === ConnectorTypes.STRIPE && state.name !== "" && state.publishableKey !== "") ||
            (state.type === ConnectorTypes.MONDAY && state.name !== "" && state.boardId !== "" && state.apiToken !== ""))) {
            return true
        }

        return false;
    }

    const handleNext = () => {
        const nextStep = state.step + 1;
        if ((state.type === ConnectorTypes.SALTEDGE_PARTNERS_V1 || state.type === ConnectorTypes.SALTEDGE_V6 || state.type === ConnectorTypes.STRIPE || state.type === ConnectorTypes.MONDAY) && nextStep === 2) {
            handleSubmit();
        } else if (state.type === ConnectorTypes.DEMO && nextStep === 2) {
            setOpenDemo(true);
        } else {
            setState({ ...state, step: nextStep });
        }
    };

    return (
        <Stack sx={{ padding: '0 1rem' }} justifyContent={"space-between"} alignItems={"center"}>
            {state.step == 0 &&
                <>
                    <Typography
                        sx={{
                            fontSize: '20px',
                            fontWeight: 500,
                        }}
                    >
                        Select the type of connector
                    </Typography>
                    <Stack direction={"row"} mt={5} mb={5} spacing={4} sx={{ maxWidth: '992px', }}>
                        <ConnectorSelector
                            type={ConnectorTypes.SALTEDGE_PARTNERS_V1}
                            text={"Salt Edge Partners"}
                            onSelect={() => setState({ ...state, type: ConnectorTypes.SALTEDGE_PARTNERS_V1 })}
                            checked={state.type == ConnectorTypes.SALTEDGE_PARTNERS_V1}
                            disabled={user?.isAdmin && userId !== impersonatedUserId ? true : false}
                        ></ConnectorSelector>
                        <ConnectorSelector
                            type={ConnectorTypes.SALTEDGE_V6}
                            text={"Salt Edge (preview)"}
                            onSelect={() => setState({ ...state, type: ConnectorTypes.SALTEDGE_V6 })}
                            checked={state.type == ConnectorTypes.SALTEDGE_V6}
                            disabled={user?.isAdmin && userId !== impersonatedUserId ? true : false}
                        ></ConnectorSelector>
                        <ConnectorSelector
                            type={ConnectorTypes.STRIPE}
                            text={"Stripe"}
                            onSelect={() => setState({ ...state, type: ConnectorTypes.STRIPE })}
                            checked={state.type == ConnectorTypes.STRIPE}
                        >
                        </ConnectorSelector>
                        <ConnectorSelector
                            type={ConnectorTypes.MONDAY}
                            text={"Monday"}
                            onSelect={() => setState({ ...state, type: ConnectorTypes.MONDAY })}
                            checked={state.type == ConnectorTypes.MONDAY}
                        >
                        </ConnectorSelector>
                        <ConnectorSelector
                            type={ConnectorTypes.DEMO}
                            text={"Salt Edge Demo"}
                            onSelect={() => setState({ ...state, type: ConnectorTypes.DEMO })}
                            checked={state.type == ConnectorTypes.DEMO}
                            disabled={!user?.isDemo}
                        >
                        </ConnectorSelector>
                    </Stack>
                </>
            }
            {state.step == 1 &&
                <Stack direction={"column"} justifyContent={"space-between"} alignItems={"center"} sx={{ width: '550px' }}>
                    <Typography sx={{ fontSize: '20px', fontWeight: 500, mb: '3rem' }}>
                        Configure your Connector
                    </Typography>
                    <Box sx={{ width: '100%' }}>
                        <Typography fontSize={12} mb={'4px'}>
                            Name your connector
                        </Typography>
                        <TextField
                            fullWidth
                            placeholder="Connection Name"
                            value={state.name}
                            onChange={(e) => setState((prevState) => ({ ...prevState, name: e.target.value }))}
                            sx={{
                                '&.MuiFormControl-root': {
                                    mb: '2rem',

                                    '.MuiInputBase-root': {
                                        height: '38px',

                                        'fieldset': {
                                            borderColor: variables.bluePrimary
                                        }
                                    }
                                }
                            }}
                        />
                    </Box>
                    <Box sx={{ width: '100%', mb: '6rem' }}>
                        <Typography fontSize={12} mb={'4px'}>
                            What types of transactions will be received through this connector?
                        </Typography>
                        <FormControl component="fieldset">

                            {(state.type === ConnectorTypes.SALTEDGE_PARTNERS_V1 || state.type === ConnectorTypes.SALTEDGE_V6 || state.type === ConnectorTypes.DEMO) &&
                                <RadioGroup
                                    row
                                    aria-label="allow"
                                    name="allow"
                                    value={state.saltedge_allow}
                                    onChange={(e) => setState({ ...state, saltedge_allow: e.target.value })}
                                    sx={{
                                        '.MuiRadio-root, .MuiRadio-checked': {
                                            color: variables.bluePrimary + ' !important',
                                        }
                                    }}
                                >
                                    <FormControlLabel value="both" control={<Radio />} label="Both" checked={state.saltedge_allow === 'both'} />
                                    <FormControlLabel value="income" control={<Radio />} label="Income" />
                                    <FormControlLabel value="expenses" control={<Radio />} label="Expenses" />
                                </RadioGroup>
                            }
                            {state.type === ConnectorTypes.STRIPE &&
                                <RadioGroup
                                    column
                                    aria-label="allow"
                                    name="allow"
                                    value={state.stripe_allow}
                                    onChange={(e) => setState({ ...state, stripe_allow: e.target.value })}
                                    sx={{
                                        '.MuiRadio-root': {
                                            color: variables.bluePrimary,
                                        }
                                    }}
                                >
                                    <FormControlLabel value="forecast" control={<Radio />} checked={state.stripe_allow === 'forecast'} label="Forecast (capture expected payouts and subscriptions)" />
                                    <FormControlLabel value="actuals" control={<Radio />} label="Actuals (capture payment intents)" />
                                    <FormControlLabel value="both" control={<Radio />} label="Both" />
                                </RadioGroup>
                            }

                        </FormControl>
                    </Box>
                    {state.type === ConnectorTypes.STRIPE &&
                        <Box sx={{ my: '0.5rem', width: '100%', }}>
                            <Typography>
                                3. Enter your credentials
                            </Typography>
                            <Box sx={{ my: '0.5rem' }}>
                                <TextField
                                    placeholder="Publishable Key"
                                    value={state.publishableKey}
                                    onChange={(e) => setState({ ...state, publishableKey: e.target.value })}
                                    sx={{
                                        '&.MuiFormControl-root': {
                                            mt: '0.5rem',
                                            width: '100%',

                                            '.MuiInputBase-root': {
                                                height: '38px',
                                                'fieldset': {
                                                    borderColor: variables.bluePrimary
                                                }
                                            }
                                        }
                                    }}
                                />
                            </Box>
                        </Box>
                    }
                    {state.type === ConnectorTypes.MONDAY &&
                        <Box sx={{ my: '0.5rem', width: '100%', }}>
                            <Typography>
                                3. Enter your credentials
                            </Typography>
                            <Box sx={{ my: '0.5rem', }}>
                                <TextField
                                    placeholder="Board Id"
                                    value={state.boardId}
                                    onChange={(e) => setState({ ...state, boardId: e.target.value })}
                                    sx={{
                                        '&.MuiFormControl-root': {
                                            mt: '0.5rem',
                                            width: '100%',

                                            '.MuiInputBase-root': {
                                                height: '38px',
                                            }
                                        }
                                    }}
                                />
                            </Box>
                            <Box sx={{ my: '0.5rem', }}>
                                <TextField
                                    placeholder="API Token"
                                    value={state.apiToken}
                                    onChange={(e) => setState({ ...state, apiToken: e.target.value })}
                                    sx={{
                                        '&.MuiFormControl-root': {
                                            mt: '0.5rem',
                                            width: '100%',

                                            '.MuiInputBase-root': {
                                                height: '38px',
                                            }
                                        }
                                    }}
                                />
                            </Box>
                        </Box>
                    }
                </Stack>
            }

            <Stack sx={{ marginTop: "auto", width: '100%', mb: '2rem' }} direction={"row"} justifyContent={state.step != 0 ? "space-between" : "center"} spacing={3}>
                <>
                    {state.step != 0 &&
                        <LoadingButton
                            loading={false}
                            onClick={() => setState({ ...state, step: state.step - 1 })}
                            sx={{ color: variables.lightGraySecondary, '&:hover': { backgroundColor: variables.blueBackground } }}
                            size='large'
                        >
                            <img src="/assets/icons/ic_arrow_back.svg" alt="arrow back icon" style={{ paddingRight: '0.5rem' }} />
                            Previous
                        </LoadingButton>
                    }
                    <LoadingButton
                        variant="outlined"
                        loading={loading}
                        disabled={!isNextAllowed()}
                        onClick={handleNext}
                        size='large'
                        sx={{
                            color: 'text.thirdary',
                            '&:hover': { background: variables.blueBackground, borderColor: variables.bluePrimary },
                            borderColor: variables.bluePrimary,
                        }}
                    >
                        Continue
                    </LoadingButton>

                </>
            </Stack>
            <DemoConnectionDrawer open={openDemo} onClose={() => setOpenDemo(false)} handleSubmit={handleSubmit} />
        </Stack>
    )
}

function ConnectorSelector({ type, text, onSelect, checked, disabled }) {
    const theme = useTheme();

    const selected = {
        transform: 'scale(1.01)',
        background: theme.palette.grey[900],
        cursor: disabled ? 'not-allowed' : 'pointer',
        boxShadow: '0 0 10px rgba(0, 0, 0, 0.5)',
        opacity: disabled ? 0.5 : 1, // Dim the item if disabled
    };
    const boxStyle = {
        padding: 1,
        border: 1,
        borderColor: theme.palette.grey[700],
        borderRadius: 1,
        width: 160,
        height: 240,
        transition: 'transform 0.3s',
        ':hover': selected, // Apply hover effect only if not disabled
    };

    return (
        <Box
            onClick={() => {
                if (!disabled) {
                    onSelect(type);
                }
            }}
            sx={boxStyle}
        >
            <Stack alignItems={"center"} spacing={3} justifyContent={"space-between"}>
                <Box sx={{ mt: '1rem', display: 'flex', alignItems: 'center', flexFlow: 'column' }}>
                    <img src={`/assets/icons/ic_${type}.png`} alt={text} width={100} />
                    <Typography textAlign={"center"} mt={"1rem"} sx={{ color: disabled && variables.lightGraySecondary }}>{text}</Typography>
                </Box>
                <Radio checked={checked} sx={{ mx: "auto", my: 0 }} disabled={disabled} />
            </Stack>
        </Box>
    );
}

function formatDate(dateString) {
    const parts = dateString.split('T'); // Split the date and time
    const datePart = parts[0]; // '2024-07-04'
    const timePart = parts[1].slice(0, -1); // Remove the 'Z' and get '21:18:40'

    // Create a new Date object
    const date = new Date(datePart);

    // Options for the date, showing day, month name, and year
    const options = { day: 'numeric', month: 'long', year: 'numeric' };

    // Format the date part using toLocaleDateString with options
    const formattedDate = date.toLocaleDateString('en-GB', options);

    // Extract only the hour and minute from the time part
    const formattedTime = timePart.slice(0, 5);

    // Combine both parts into the final string
    return `${formattedDate} @ ${formattedTime}`;
}

function DemoConnectionDrawer({ open, onClose, handleSubmit }) {
    const BankItem = styled(Card)({
        padding: 16,
        textAlign: 'center',
        color: '#fff',
        marginBottom: 10,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        backgroundColor: variables.darkGraySecondary,
        transition: 'transform 0.3s',
        "&:hover": {
            transform: 'scale(1.05)',
            cursor: 'pointer',
            boxShadow: '0 0 10px rgba(0, 0, 0, 0.5)',
        }
    });

    const BankIcon = styled('img')({
        width: 50,
        height: 50,
        marginBottom: 8,
    });

    const [step, setStep] = useState(1);
    const [consentGiven, setConsentGiven] = useState(false);

    const handleConnect = async () => {
        setStep(4);
        setTimeout(() => {
            setStep(5);
        }, 8000);
        setTimeout(() => {
            handleSubmit();
            onClose();
        }, 13000);

    }

    return (
        <Dialog
            open={open}
            onClose={onClose}
            aria-labelledby="form-dialog-title"
            fullWidth
            sx={{
                '& .MuiDialog-paper': {
                    maxWidth: '500px',
                    minHeight: '800px',
                }
            }}
        >
            <DialogActions>
                <IconButton onClick={onClose}><CloseIcon /></IconButton>
            </DialogActions>
            {step === 1 &&
                <DialogContent sx={{ padding: '2rem' }}>
                    <Typography variant="h6" gutterBottom>
                        Choose your bank
                    </Typography>
                    <TextField fullWidth margin="normal" placeholder="Search for a bank" variant="outlined" />
                    <Grid container spacing={2} sx={{ marginTop: '1rem' }}>
                        {['Demo Bank 1', 'Demo Bank 2', 'Demo Bank 3', 'Demo Bank 4', 'Demo Bank 5', 'Demo Bank 6'].map((bank, index) => (
                            <Grid item xs={6} key={index} onClick={() => setStep(2)}>
                                <BankItem elevation={2}>
                                    <BankIcon src={`/assets/icons/ic_bank.png`} alt={bank} />
                                    <Typography>{bank}</Typography>
                                </BankItem>
                            </Grid>
                        ))}
                    </Grid>
                </DialogContent>
            }
            {step === 2 &&
                <DialogContent sx={{ padding: '2rem' }}>
                    <Typography variant="h6" gutterBottom>
                        Enter your credentials
                    </Typography>
                    <TextField fullWidth margin="normal" label="Login" variant="outlined" />
                    <TextField fullWidth margin="normal" label="Password" variant="outlined" />
                    <Stack spacing={2} mt={2} direction={"row"}>
                        <Button variant="outlined" fullWidth size='large' onClick={() => setStep(1)}>Cancel</Button>
                        <Button variant="contained" fullWidth size='large' onClick={() => setStep(3)} sx={{ color: 'black' }}>Proceed</Button>
                    </Stack>
                </DialogContent>
            }
            {step === 3 &&
                <DialogContent>
                    <Typography variant="h6" gutterBottom>Consent to read-only access to data</Typography>
                    <Typography>In order to provide you account information services (a consolidated overview of your accounts, regular payments and transactions via Dashboard/Digest), Salt Edge Limited needs your approval to access the following information from the account you hold at Demo Bank:</Typography>
                    <Accordion>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <Typography>Your Contact Details</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>Access to your contact information.</Typography>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <Typography>Your Account Details</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>Access to your account details like balance, account number.</Typography>
                        </AccordionDetails>
                    </Accordion>
                    <FormControlLabel
                        control={<Checkbox checked={consentGiven} onChange={(e) => setConsentGiven(e.target.checked)} />}
                        label="By ticking this box, you are also giving your GDPR consent to Salt Edge Limited to share the above information and future updates with Tamzen Technology Ltd (acting via Tamzen Technology Limited) in order for them to provide you the requested services."
                    />
                    <Button fullWidth variant="contained" color="primary" size="large" onClick={handleConnect} disabled={!consentGiven} sx={{ color: 'black', marginTop: '1rem' }}>Confirm</Button>
                </DialogContent>
            }
            {step === 4 &&
                <DialogContent>
                    <Stack spacing={2}>
                        <Typography variant="h6" gutterBottom>Connecting</Typography>
                        <Typography>Establishing a secure connection...</Typography>
                        <LinearProgress />
                    </Stack>
                </DialogContent>
            }
            {step === 5 &&
                <DialogContent>
                    <Stack spacing={2}>
                        <Typography variant="h6" gutterBottom>Connection successful</Typography>
                        <Typography>Your account has been successfully connected to Demo Bank.</Typography>
                    </Stack>
                </DialogContent>
            }
        </Dialog>
    );
}