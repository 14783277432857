import AovOrdersWidget, { AovOrdersPreview, AovOrdersSelector } from "./AovOrders";
import RevenueOrdersWidget, { RevenueOrdersPreview, RevenueOrdersSelector } from "./RevenueOrders";
import TotalOrdersWidget, { TotalOrdersPreview, TotalOrdersSelector } from "./TotalOrders";

const { WidgetTypes, DataProviderFilter } = require("src/shared/enums");

const widgetMapping = {};

widgetMapping[WidgetTypes.total_orders] = {
    preview: TotalOrdersPreview,
    selector: TotalOrdersSelector,
    widget: TotalOrdersWidget,
    config: {
        path: 'total_orders',
        allowFullscreen: true,
        enabledFilters: {
            [DataProviderFilter.country]: {},
            [DataProviderFilter.periodRange]: {},
        }
    },
    allowFullscreen: true
};

widgetMapping[WidgetTypes.aov_orders] = {
    preview: AovOrdersPreview,
    selector: AovOrdersSelector,
    widget: AovOrdersWidget,
    config: {
        path: 'aov_orders',
        allowFullscreen: true,
        enabledFilters: {
            [DataProviderFilter.country]: {},
            [DataProviderFilter.periodRange]: {},
        }
    },
    allowFullscreen: true
};

widgetMapping[WidgetTypes.revenue_orders] = {
    preview: RevenueOrdersPreview,
    selector: RevenueOrdersSelector,
    widget: RevenueOrdersWidget,
    config: {
        path: 'revenue_orders',
        allowFullscreen: true,
        enabledFilters: {
            [DataProviderFilter.country]: {},
            [DataProviderFilter.periodRange]: {},
        }
    },
    allowFullscreen: true
};

export const WidgetMapping = widgetMapping;