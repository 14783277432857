import { Close } from "@mui/icons-material";
import { Alert, Box, Container, Drawer, IconButton, Slide, Snackbar, Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { ErrorCodes } from "src/shared/errors";

function SlideTransition(props) {
    return <Slide {...props} direction="down" />;
}

export default function AppDrawer({ error, title, titleComponent, children, open, onClose, onErrorDispatched, height, width, anchor }) {
    //use an internal state to avoid flickers 
    const [alert, setAlert] = useState(error ? ErrorCodes[error]?.message : null);

    useEffect(() => {
        if (error && ErrorCodes[error].message !== alert)
            setAlert(ErrorCodes[error].message)
    // eslint-disable-next-line
    }, [error]);

    return (
        <Drawer anchor={anchor ?? 'bottom'} open={open} onClose={onClose} classes={{
            paper: {
                boxShadow: 'none',
            }
        }}>
            <Container sx={{ height: height + "vh", paddingTop: 4, maxWidth: width + '% !important' ?? '' }}>
                <Snackbar
                    autoHideDuration={3000}
                    anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                    open={error != null}
                    onClose={(event, reason) => {
                        onErrorDispatched && onErrorDispatched();
                    }}
                    TransitionComponent={SlideTransition}
                    message={alert}
                >
                    <Alert variant={"outlined"} severity={"error"} sx={{ color: "white" }}>{alert}</Alert>
                </Snackbar>
                <Stack direction={"row"} alignItems={"center"}>
                    {title &&
                        <Typography variant="h6" fontWeight={"bold"}>
                            {title}
                        </Typography>
                    }
                    {titleComponent &&
                        <>{titleComponent}</>
                    }
                    <Box marginLeft={"auto"}>
                        <IconButton onClick={onClose}>
                            <Close></Close>
                        </IconButton>
                    </Box>
                </Stack>
                {
                    children
                }
            </Container>
        </Drawer>
    );
}