import { Box, Button, Card, CardHeader, Stack, Tooltip, Typography } from "@mui/material"

import variables from 'src/styles/variables.scss';
import { WidgetChartOptions, WidgetOverlayStyle, WidgetPreviewImage } from "../../../styles/widgetStyles";
import { DataFilterGranularities, WidgetTypes } from "src/shared/enums";
import { useChart } from "src/components/chart";
import Chart from "react-apexcharts";
import { useDataProvider } from "src/hooks/useDataProvider";
import { useEffect } from "react";
import DataTable from "src/components/DataTable";

export const RevenueOrdersPreview = () => {
    return (
        <Stack sx={WidgetOverlayStyle(WidgetTypes.orders)} justifyContent={"center"} alignItems={"center"}>
            <Tooltip title={
                <Typography>
                    This is the orders widget
                </Typography>
            }>
                <Stack direction={"row"} sx={{ backgroundColor: variables.darkGraySecondary, padding: 2, border: "1px solid white" }} spacing={2} alignItems={"center"}>
                    <Typography variant="body">
                        Orders widget
                    </Typography>
                </Stack>
            </Tooltip>
        </Stack>
    )
}

export const RevenueOrdersSelector = ({ onClick }) => {
    const previewUrl = WidgetPreviewImage("orders");

    return (
        <Stack justifyContent={"center"} alignItems={"center"} spacing={2}>
            <Box sx={{
                height: 100,
                width: 100,
                border: "1px solid #333333",
                borderRadius: 3,
                backgroundImage: `${previewUrl}`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat"
            }}>
            </Box>
            <Typography variant={"body2"}>Orders Widget</Typography>
            <Button size="small" variant={"outlined"} onClick={onClick}>Select</Button>
        </Stack>
    )
}

const WidgetViewModes = Object.freeze({
    widget: "widget",
    fullscreen: "fullscreen"
});

export default function RevenueOrdersWidget({ fullscreen }) {

    const dataProvider = useDataProvider();

    useEffect(() => {
        const newFilter = {
            ...dataProvider.filter, path: `sets/orders`,
            fullscreen: fullscreen,
        };
        dataProvider.setFilter(newFilter);
    }, []);

    const { dataSets } = dataProvider.data ?? { table: null, dataSets: null };

    const revenueSeries = [{
        name: dataSets?.revenue?.name ?? '',
        type: 'column',
        fill: 'solid',
        data: dataSets?.revenue?.data?.map(i => i.value) ?? [],
    }] ?? [];

    const chartOptions = useChart({
        ...WidgetChartOptions,
        plotOptions: {
            bar: {
                columnWidth: '60%',
                borderRadius: 0,
            },
        },
        labels: dataSets?.revenue?.data?.map((i) => i.point) ?? [],
        fill: {
            opacity: [1, 1, 1],
        },
        stroke: {
            width: 0,
        },
        colors: [variables.bluePrimary, variables.orangePrimary, variables.greenPrimary],
    });

    const granularity = dataProvider.filter.granularity === DataFilterGranularities.monthly ?
        (dataSets?.revenue?.data?.length > 1 ? "months" : "month") : (dataSets?.revenue?.data?.length > 1 ? "weeks" : "week");

    return (
        <>
            <Card sx={{ boxShadow: 'none', background: variables.darkGrayPrimary, border: '1px solid ' + variables.midGrayPrimary, borderRadius: '4px' }}>
                <CardHeader
                    title={"Total Revenue"}
                    subheader={!dataProvider.loading ? `${dataSets?.revenue?.data?.length ?? 0} ${granularity}` : '-'}
                    sx={{
                        '.MuiCardHeader-title': {
                            fontSize: '24px',
                        },
                        '.MuiCardHeader-subheader': {
                            fontSize: '12px',
                            color: variables.lightGraySecondary,
                        }
                    }}
                />
                <Box sx={{ p: 3, pb: 1 }} dir="ltr">
                    <Chart type="bar" series={revenueSeries} options={chartOptions} height={345} />
                </Box>
            </Card>
            {fullscreen &&
                <Box sx={{ mt: 5 }}>
                    <Typography sx={{ fontSize: '18px', fontWeight: 600, px: 3 }}>Orders Detailed View</Typography>
                    <DataTable
                        dataProvider={dataProvider}
                        type={"orders"}
                        editData={false}
                        loadMoreData={false}
                    >
                    </DataTable>
                </Box>
            }
        </>
    );
}

