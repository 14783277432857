import React from 'react';
import { Helmet } from 'react-helmet-async';

import { Box, Button, CircularProgress, IconButton, Tooltip, Typography } from '@mui/material';
import 'src/styles/styles.scss';

import { WidgetTypes } from 'src/shared/enums';
import { WidgetMapping } from 'src/pages/cashflow/components';
import { DataProvider, useDataProvider } from 'src/hooks/useDataProvider';
import { useNavigate } from 'react-router-dom';
import variables from 'src/styles/variables.scss';

export default function CashflowPage() {
    return (
        <>
            <Helmet>
                <title>Eleni Finance Dashboard</title>
            </Helmet>
            <PageTemplate></PageTemplate>
        </>
    );
}

const WidgetBox = ({ component, config = {}, path }) => {
    const navigate = useNavigate();

    const handleFullscreen = () => {
        // Navigate to the fullscreen route of the widget
        navigate(`/overview/${path}`, { state: { config } });
    };

    const WidgetComponent = component;
    return (
        <Box style={{ width: "100%", position: "relative" }}>
            {
                component != null &&
                <>
                    {config?.allowFullscreen == true &&
                        <Box style={{ position: "absolute", top: '1.5rem', right: '1rem', zIndex: 2 }}>
                            <Tooltip title="Open detailed view">
                                <IconButton onClick={handleFullscreen} >
                                    <img src="/assets/icons/ic_expand.svg" alt="expand button" />
                                </IconButton>
                            </Tooltip>
                        </Box>
                    }
                    <WidgetComponent fullscreen={false}></WidgetComponent>
                </>
            }
        </Box>
    )
}

const PrepareButton = () => {
    const dataProvider = useDataProvider();

    return (
        <Box sx={{ display: 'flex', flexFlow: 'row', justifyContent: 'end', alignItems: 'center', }}>
            <Box sx={{ height: '42px', mr: '0.5rem' }}>
                <CircularProgress size={20} sx={{ display: dataProvider.loading ? "block" : "none", }}></CircularProgress>
            </Box>
            <Button
                variant='outlined'
                sx={{
                    height: '42px',
                    color: variables.lightGrayPrimary,
                    fontSize: '12px',
                    border: '1px solid ' + variables.borderGray,
                    borderRadius: '4px',
                    fontWeight: 400,
                    mb: '1rem',
                    '&:hover': {
                        borderColor: variables.borderGray,
                    }
                }}
                onClick={() => dataProvider.prepareData()}
            >
                Request forecast
            </Button>
        </Box>
    )
}

const PageTemplate = ({ disabled }) => {
    const settings = {
        grid: [[1], [1, 1], [1], [1]],
        widgets: [WidgetTypes.cash_balance, WidgetTypes.cashin_card, WidgetTypes.cashout_card, WidgetTypes.transactions, WidgetTypes.forecast]
    };
    let widgetIndex = -1;

    return (
        <>
            <Box sx={{ opacity: disabled ? 0.2 : 1 }}>
                <Typography sx={{ fontSize: '12px', fontWeight: 500, position: 'absolute', pt: '0.5rem' }}>
                    Cashflow
                </Typography>
                <DataProvider filterConfig={WidgetMapping[WidgetTypes.cash_balance].config.enabledFilters} styles={{ marginBottom: '1rem', }}>
                    {
                        settings?.grid?.map((line, lineIndex) => {
                            const columns = line;
                            const gridTemplate = line.map(c => { return "1fr" }).join(" ");
                            return (
                                <Box display="grid" key={`grid_box_line_${lineIndex}`} gridTemplateColumns={gridTemplate} gap={3} mb={3}>
                                    {
                                        columns.map((row, column) => {
                                            widgetIndex++;
                                            const widgetKey = settings?.widgets[widgetIndex];
                                            const widget = WidgetMapping[widgetKey]

                                            const WidgetComponent = widget?.widget ?? null;
                                            const config = widget?.config ?? {};
                                            const path = widget?.config?.path ?? '';

                                            return (
                                                <WidgetBox
                                                    key={`widget_${row}_${column}`}
                                                    component={WidgetComponent}
                                                    path={path}
                                                    config={config}
                                                ></WidgetBox>
                                            )
                                        })
                                    }
                                </Box>
                            )
                        })
                    }
                </DataProvider>
            </Box>
        </>
    )
}

