import { LoadingButton } from "@mui/lab";
import { Box, Container, FormControl, Stack, Tab, Tabs, TextField, Typography } from "@mui/material";
import { useState } from "react";
import variables from 'src/styles/variables.scss';
import { ComingSoon } from "src/utils/utils";


export default function AccountSecurityPage() {
    const [tab, setTab] = useState(0);

    return (
        <Container>
            <Stack direction={"row"} my={2} alignItems={"center"} spacing={3}>
                <Typography sx={{ fontSize: '14px', color: variables.lightGraySecondary, fontWeight: 600 }}>Security and Privacy</Typography>
            </Stack>
            <Tabs value={tab} sx={{ marginTop: 4, marginBottom: 4, '.MuiTabs-flexContainer': { alignItems: 'center' } }}>
                <Tab label="Password & 2FA" onClick={() => setTab(0)} />
                <Tab
                    label="Notification Settings"
                    disabled
                    onClick={() => setTab(1)}
                    sx={{
                        '.MuiButtonBase-root.Mui-disabled.MuiTab-root': {
                            color: variables.lightGrayPrimary + ' !important'
                        }
                    }}
                />
                <ComingSoon></ComingSoon>
            </Tabs>
            {tab === 0 &&
                <Stack mt={4} spacing={4}>
                    <ChangePaswordForm></ChangePaswordForm>
                    <TwoFactorForm></TwoFactorForm>
                </Stack>
            }
            {tab === 1 &&
                <></>
            }
        </Container>
    )
}

function TwoFactorForm() {
    return (
        <>
            <Stack direction={"column"} spacing={3}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Typography variant="h6" sx={{ mr: '1rem' }}>2FA - Two factor authentication </Typography>
                    <ComingSoon></ComingSoon>
                </Box>
                <Typography variant="body2">Two factor authentication adds an extra layer of security to your account. Once enabled, you will be asked to enter a unique code from your authenticator app when you sign in.</Typography>
            </Stack>
            <LoadingButton size="large" variant={"outlined"} disabled>Enable 2FA</LoadingButton>
        </>
    )

}

function ChangePaswordForm() {
    const [dirty, setDirty] = useState(false);
    return (
        <>
            <FormControl>
                <TextField
                    type={"password"}
                    label="Enter Current Password"
                    placeholder="Enter password"
                    onChange={() => setDirty(true)}>

                </TextField>
            </FormControl>
            <FormControl>
                <TextField
                    type={"password"}
                    label="Enter New Password"
                    placeholder="Enter password"
                    onChange={() => setDirty(true)}>

                </TextField>
            </FormControl>
            <FormControl>
                <TextField
                    type={"password"}
                    label="Confirm New Password"
                    placeholder="Confirm new password"
                    onChange={() => setDirty(true)}
                ></TextField>
            </FormControl>
            <LoadingButton size="large" variant={"contained"} disabled={!dirty} sx={{ color: 'black', boxShadow: 'none' }}>Submit</LoadingButton>
        </>
    )
}