import { Box, Card, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { useDataProvider } from "src/hooks/useDataProvider";
import { DataFilterGranularities } from "src/shared/enums";
import variables from 'src/styles/variables.scss';
import 'src/styles/styles.scss';
import moment from "moment";
import 'moment/locale/en-gb';
import DataTable from "src/components/DataTable";
import { ChartsGrid, ChartsReferenceLine, ChartsTooltip, ChartsXAxis, ChartsYAxis, LineHighlightPlot, LinePlot, MarkPlot, ResponsiveChartContainer } from "@mui/x-charts";

export default function CashBalanceChart({ fullscreen = false }) {
    const dataProvider = useDataProvider();

    useEffect(() => {
        const newFilter = {
            ...dataProvider.filter, path: `sets/cashflow`,
            fullscreen: fullscreen,
            start: moment().subtract(12, 'week').startOf('week').format('YYYY-MM-DD'),
            end: dataProvider.filter.granularity === DataFilterGranularities.weekly ? moment().add(12, 'week').endOf('week').format('YYYY-MM-DD') : moment().add(2, 'month').endOf('month').format('YYYY-MM-DD'),
        };
        dataProvider.setFilter(newFilter);
    }, []);

    const currency = dataProvider.filter.currency.toUpperCase();

    const { cashflowSet: widgetData } = dataProvider?.data ?? [];

    if (dataProvider.filter.granularity === DataFilterGranularities.monthly) {
        widgetData?.forecastBalance.shift();
    }

    const actualSeries = widgetData?.actualsBalance ?? [];
    const forecastSeries = widgetData?.forecastBalance ?? [];

    function findValueByGranularity(data, weekString) {
        const item = data.find(d => d.point === weekString);
        return item ? item.value : 0;
    }

    const currentDate = moment();
    const lastDate = dataProvider.filter.granularity === DataFilterGranularities.weekly ? moment().subtract(1, 'week') :
        dataProvider.filter.granularity === DataFilterGranularities.monthly ? moment().subtract(1, 'month') : moment().subtract(1, 'day');
    const formatDateType = dataProvider.filter.granularity === DataFilterGranularities.weekly ? 'YYYY-[W]ww' :
        dataProvider.filter.granularity === DataFilterGranularities.monthly ? 'YYYY-MM' : 'YYYY-MM-DD';

    const todaysValue = findValueByGranularity(actualSeries, currentDate.format(formatDateType));
    const yesterdaysValue = findValueByGranularity(actualSeries, lastDate.format(formatDateType));
    const diff = todaysValue - yesterdaysValue;
    const percentageDiff = yesterdaysValue !== 0 ? ((diff / yesterdaysValue) * 100).toFixed(2) : 0;

    let x = -1;
    const actualsXAxis = actualSeries?.map(item => {
        x++;
        return {
            point: x, date: dataProvider.filter.granularity === DataFilterGranularities.weekly
                ? moment(item?.point).format(`[w]WW MMM DD`)
                : moment(item?.point).format(`MMM 'YY`)
        };
    });

    const forecastXAxis = forecastSeries?.map(item => {
        x++;
        return {
            point: x, date: dataProvider.filter.granularity === DataFilterGranularities.weekly
                ? moment(item?.point).format(`[w]WW MMM DD`)
                : moment(item?.point).format(`MMM 'YY`)
        };
    });

    const combinedXAxis = actualsXAxis.concat(forecastXAxis);

    // Processing the Y-axis data for actuals
    const actualsYAxis = actualSeries?.map(item => item?.value ?? 0);
    const forecastYAxis = forecastSeries.map(item => item?.value?.average ?? 0);

    const combinedYAxis = actualsYAxis.concat(forecastYAxis);

    const [integerPart, decimalPart] = todaysValue.toLocaleString('us-US').split('.');

    const config = {
        series: [
            { type: 'line', data: combinedYAxis, showMark: true },
        ],
        resolveSizeBeforeRender: true,
        height: 320,
        xAxis: [
            {
                scaleType: 'point',
                data: combinedXAxis.map(item => item.point),
                valueFormatter: (point, context) => {
                    const item = combinedXAxis.find(item => item.point === point);
                    return context.location === 'tick'
                        ? (dataProvider.filter.granularity === DataFilterGranularities.weekly
                            ? `${item.date.slice(0, 3)} \n${item.date.slice(4, 11)}`
                            : `${item.date}`)
                        : `${item.date}`;
                },
                colorMap: {
                    type: 'piecewise',
                    thresholds: [actualsXAxis[actualsXAxis.length - 1]?.point],
                    colors: [variables.bluePrimary, variables.greenPrimary],
                },
            },
        ],
        yAxis: [
            {
                tickMinStep: 500,
                valueFormatter: (value) => {
                    let absValue = Math.abs(value);
                    let sign = value < 0 ? '-' : ''; // Check if the original value was negative
                    if (absValue < 1000) {
                        return sign + absValue.toFixed(2); // Reapply the negative sign if needed
                    } else if (absValue < 1000000) {
                        return sign + (absValue / 1000).toLocaleString('us-US', {
                            minimumFractionDigits: 1,
                            maximumFractionDigits: 1
                        }) + 'K  ';
                    } else {
                        return sign + `${(absValue / 1000000).toLocaleString('us-US', { minimumFractionDigits: 1, maximumFractionDigits: 1 })}M  `;
                    }
                },
            }
        ],
        sx: {
            '.MuiChartsAxis-tickLabel': {
                fill: variables.midGraySecondary + ' !important',
            },
        }
    };

    const dynamicStyles = {};
    const indices = forecastXAxis.map(item => item.point + 1);

    indices.forEach((index) => {
        dynamicStyles[`&:nth-child(${index})`] = {
            stroke: variables.greenPrimary,
            opacity: 0.3,
        };
    });

    return (
        <>
            <Card
                sx={{
                    height: '405px',
                    p: '1.5rem',
                    boxShadow: 'none',
                    background: variables.darkGraySecondary,
                    borderRadius: '4px',
                    position: 'relative',
                    display: 'flex',
                    flexFlow: 'column',
                    justifyContent: 'space-between',
                }}
            >
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Box>
                        <Typography sx={{ fontSize: '12px', fontWeight: 600 }}>Cash Balance</Typography>
                        <Typography sx={{ fontSize: '24px', fontWeight: 600, display: 'flex', alignItems: 'baseline', lineHeight: 1 }}>
                            {currency + ' ' + integerPart}
                            {decimalPart &&
                                <span style={{ fontSize: '14px', fontWeight: 500 }}>.{decimalPart}</span>
                            }
                            <span
                                style={{
                                    fontSize: '12px',
                                    color: diff > 0 ? variables.greenPrimary : diff < 0 ? variables.redPrimary : variables.lightGrayPrimary,
                                    display: 'flex',
                                    alignItems: 'center'
                                }}
                            >
                                <img
                                    src={diff < 0 ? "/assets/icons/ic_stats_arrow_down.svg" : "/assets/icons/ic_stats_arrow_up.svg"}
                                    style={{ padding: '0 0.5rem 0 1rem' }}
                                />
                                {Math.abs(percentageDiff)}%
                                <span style={{ color: variables.lightGrayPrimary, fontWeight: 400, padding: '0 0.5rem' }}>
                                    vs. {dataProvider.filter.granularity === DataFilterGranularities.weekly ? ' last week' :
                                        dataProvider.filter.granularity === DataFilterGranularities.monthly ? ' last month' : ' yesterday'}</span>
                            </span>
                        </Typography>
                    </Box>
                </Box>
                <Box dir="ltr">
                    {!dataProvider.loading &&
                        <ResponsiveChartContainer {...config}>
                            <LinePlot slotProps={{ line: { limit: 5, sxAfter: { strokeDasharray: '4' } } }} />
                            <ChartsReferenceLine
                                x={actualsXAxis[actualsXAxis.length - 1]?.point ?? null}
                                lineStyle={{ strokeDasharray: '4' }}
                                labelStyle={{ fontSize: '14' }}
                                label={`Forecast`}
                                labelAlign="start"
                            />
                            <ChartsXAxis disableLine disableTicks />
                            <ChartsYAxis disableLine disableTicks />
                            <MarkPlot />
                            <ChartsGrid vertical={true}
                                classes={{
                                    root: {
                                        display: 'none'
                                    },
                                }}
                                sx={{
                                    '.MuiChartsGrid-verticalLine': {
                                        strokeDasharray: '4',
                                        ...dynamicStyles,
                                    }
                                }}
                            />
                            <LineHighlightPlot />
                            <ChartsTooltip />
                        </ResponsiveChartContainer>
                    }
                </Box>
            </Card>
            {fullscreen &&
                <Box sx={{ mt: 5 }}>
                    <Typography sx={{ fontSize: '18px', fontWeight: 600, px: 3, py: 1 }}>Transactions Detailed View</Typography>
                    <DataTable
                        dataProvider={dataProvider}
                        type={'transactions'}
                        editData={false}
                        loadMoreData={false}
                    >
                    </DataTable>
                </Box>
            }
        </>
    );
};
