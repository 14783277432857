import { styled } from '@mui/material/styles';
import { Stack, AppBar, Toolbar, Typography, Container, Tabs, Tab, Box } from '@mui/material';
import 'src/styles/account.scss';

import { bgBlur } from 'src/lib/cssStyles';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

export default function TermsAndPrivacyPage() {
  const HEADER_HEIGHT = 64;

  const [activeTab, setActiveTab] = useState(0);
  const location = useLocation();

  useEffect(() => {
    if (location.hash === '#terms-of-service') {
      setActiveTab(0);
    } else if (location.hash === '#privacy-policy') {
      setActiveTab(1);
    }
  }, [location.hash]);

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const StyledRoot = styled(AppBar)(({ theme }) => ({
    ...bgBlur({ color: theme.palette.background.default }),
    boxShadow: 'none',
    paddingBottom: '1px',
    borderBottom: '1px solid #20242E',
  }));

  const StyledToolbar = styled(Toolbar)(({ theme }) => ({
    minHeight: HEADER_HEIGHT + 'px !important',
    [theme.breakpoints.up('lg')]: {
      padding: theme.spacing(0, 1.5, 0, 0),
    },
  }));

  useEffect(() => {
    const handleAnchorClick = (event) => {
      if (event.target.tagName === 'A' && event.target.getAttribute('href').startsWith('#')) {
        event.preventDefault();
        const targetId = event.target.getAttribute('href').substring(1);
        const targetElement = document.getElementById(targetId);
        if (targetElement) {
          const headerOffset = 64; // Fixed offset value
          const elementPosition = targetElement.getBoundingClientRect().top + window.scrollY;
          const offsetPosition = elementPosition - headerOffset;

          window.scrollTo({
            top: offsetPosition,
            behavior: 'smooth'
          });
        }
      }
    };

    document.addEventListener('click', handleAnchorClick);

    return () => {
      document.removeEventListener('click', handleAnchorClick);
    };
  }, []);

  const tab_style = {
    maxWidth: '100%',
    width: '50%',
  }

  return (
    <>
      <StyledRoot>
        <StyledToolbar>
          <Stack direction="row" alignItems="flex-end" spacing={3} sx={{ width: '100%', display: 'flex', justifyContent: 'start' }}>
            <a href="/overview">
              <img src="/assets/icons/navbar/ic_logo_lg.svg" alt="Logo" style={{ width: '100px', height: 'auto', margin: '0 0.5rem 0 1.5rem' }} />
            </a>
            <Typography sx={{ fontSize: '20px', lineHeight: 1 }}>
              Terms and Privacy
            </Typography>
          </Stack>
        </StyledToolbar>
      </StyledRoot>
      <Container>
        <Box pt={10} pb={3}>
          <Tabs value={activeTab} onChange={handleTabChange} centered>
            <Tab label="Terms of Use" sx={tab_style} id="terms-of-service"/>
            <Tab label="Privacy Policy" sx={tab_style} id="privacy-policy"/>
          </Tabs>

          <Stack direction={"column"} spacing={3} pt={3}>
            {activeTab === 0 && (
              <div className="terms-privacy-page">
                <div dangerouslySetInnerHTML={{ __html: termsContent }}></div>
              </div>
            )}
            {activeTab === 1 && (
              <div className="terms-privacy-page">
                <div dangerouslySetInnerHTML={{ __html: privacyContent }}></div>
              </div>
            )}
          </Stack>
        </Box>
      </Container>
    </>
  );
}

const privacyContent = `
    <div>
        <h1>Privacy Policy</h1>
        <p class="terms-privacy-page-date">Last updated September 30, 2024</p>
    </div>
    <div>
        <p>At <b>app.eleni.finance</b>, we are committed to respecting your privacy and ensuring that your personal and financial data is protected. This Privacy Policy explains how we collect, use, and safeguard your information in line with UK data protection laws, including the UK General Data Protection Regulation (UK GDPR). Please take a moment to read it carefully.</p>
    </div>
    <div id="information-collected">
        <h4>1. What Information Do We Collect?</h4>
        <p><b>Personal Information You Provide to Us</b></p>
        <p>We collect personal information that you voluntarily provide when you register for our Service, express interest in our products or services, or engage with us in any other way. This includes:</p>
        <ul>
            <li>name</li>
            <li>email address</li>
            <li>telephone number</li>
            <li>company name</li>
        </ul>
        <p><b>Financial Information via User Consent</b></p>
        <p>When you connect a financial or business tool (such as bank accounts or financial platforms) through our Service, we gain access to financial data. This may include transaction data, account balances, and account details. This access is granted through your explicit consent and managed via our open banking provider, <b>Salt Edge</b>.</p>
        <p>Please note that Salt Edge handles certain aspects of your financial data processing. You can review their privacy practices.</p>
        <p>It is your responsibility to ensure the information you provide is accurate, complete, and current. Please notify us of any changes.</p>
    </div>
    <div id="information-processing">
        <h4>2. How Do We Process Your Information?</h4>
        <p>We process your personal and financial data for the following purposes:</p>
        <ul>
            <li><b>Account Creation and Authentication:</b> To enable you to create and manage your user account.</li>
            <li><b>Security and Fraud Prevention:</b> To ensure the safety and security of your personal and financial information.</li>
            <li><b>Service Delivery:</b> To provide and improve our Service, including access to financial tools, in accordance with your consent.</li>
            <li><b>Compliance with Legal Obligations:</b> To meet our legal obligations under UK law, including financial regulations and data protection laws.</li>
        </ul>
        <p>We will only process your information for the purposes you have explicitly consented to or as required by law.</p>
    </div>
    <div id="information-processing-legal-base">
        <h4>3. Legal Bases for Processing Your Information</h4>
        <p>Under UK GDPR, we rely on the following legal bases for processing your information:</p>
        <ul>
            <li><b>Consent:</b> When you give explicit consent to the collection and processing of your personal and financial information.</li>
            <li><b>Legal Obligation:</b> When we are required to process your data to comply with a legal requirement, such as tax laws or financial regulations.</li>
            <li><b>Legitimate Interest:</b> When the processing is necessary for our legitimate business interests and does not override your rights.</li>
        </ul>
        <p>You may withdraw your consent at any time, though this may affect your ability to use certain features of our Service.</p>
    </div>
    <div id="information-sharing">
        <h4>4. When and With Whom Do We Share Your Information?</h4>
        <p>We may share your personal and financial data in the following situations:</p>
        <ul>
          <li><b>Third-Party Vendors and Service Providers:</b> We work with third-party vendors, including <b>Salt Edge</b>, to provide essential services such as financial data processing. These vendors are contractually bound to handle your data securely and in accordance with applicable laws. Please review Salt Edge’s privacy policy.</li>
          <li><b>Business Transfers:</b> In the event of a merger, acquisition, or sale of company assets, your personal data may be transferred as part of the business transaction.</li>
          <li><b>Compliance with Law:</b> We may disclose your personal or financial data to comply with legal obligations, such as court orders or regulatory requirements.</li>
        </ul>
        <p>We do not share your personal data with third parties for marketing purposes without your explicit consent.</p>
    </div>
    <div id="financial-tool-data">
        <h4>5. How Do We Handle Financial Tool Data?</h4>
        <p>Through our platform, users cannot log in using financial tools. However, we gain access to certain financial data when you connect external financial tools or business operation tools (such as bank accounts or financial platforms) to our Service. This data, which may include transaction details, account balances, and account identifiers, is accessed only with your explicit consent and is used solely for providing and improving our Service. We utilise Salt Edge as our open banking provider to facilitate secure data connections in compliance with UK regulations. For more information on how Salt Edge handles your data, please review their privacy policy.</p>
    </div>
    <div id="information-storing-period">
        <h4>6. How Long Do We Keep Your Information?</h4>
        <p>We retain your personal and financial information only for as long as necessary to fulfil the purposes for which it was collected, or as required by law. When your data is no longer needed for business or legal purposes, we will securely delete or anonymize it.</p>
    </div>
    <div id="information-storing-safety">
      <h4>7. How Do We Keep Your Information Safe?</h4>
      <p>We use industry-standard security measures to protect your personal and financial information from unauthorized access, alteration, or disclosure. These measures include encryption, access controls, and secure data storage. However, no system is entirely secure, and we cannot guarantee absolute protection of your data.</p>
      <p>We ask that you also take steps to secure your account by using strong, unique passwords and notifying us immediately if you suspect any unauthorized access to your account.</p>
    </div>
    <div id="information-minors">
        <h4>8. Do We Collect Information from Minors?</h4>
        <p>Our Service is not intended for individuals under the age of 18. We do not knowingly collect or process personal information from minors. If we learn that data from a person under 18 has been collected, we will delete it as soon as possible. If you become aware of such information, please contact us at <a href="mailto:privacy@eleni.finance">privacy@eleni.finance</a>.</p>
    </div>
    <div id="privacy-rights">
        <h4>9. Your Privacy Rights</h4>
        <p>As a resident of the UK, you have certain rights under UK GDPR, including:</p>
        <ul>
          <li><b>Access:</b> You can request access to the personal information we hold about you.</li>
          <li><b>Rectification:</b> You can request corrections to inaccurate or incomplete data.</li>
          <li><b>Erasure:</b> You can request the deletion of your personal data, subject to certain conditions.</li>
          <li><b>Restriction:</b> You can request that we limit the processing of your personal data.</li>
          <li><b>Objection:</b> You have the right to object to the processing of your data where we rely on legitimate interests.</li>
        </ul>
        <p>You can exercise these rights by contacting us at the details provided below.</p>
    </div>
    <div id="notice-updates">
        <h4>10. Updates to This Privacy Policy</h4>
        <p>We may update this Privacy Policy from time to time to reflect changes in our practices or legal requirements. The updated policy will be posted on our website with a revised date. We encourage you to review this policy periodically to stay informed about how we protect your data.</p>
    </div>
    <div id="notice-contact">
        <h4>11. How to Contact Us</h4>
        <p>If you have any questions, concerns, or requests related to this Privacy Policy or your personal data, please contact us at:</p>
        <p><b>Email:</b> <a href="mailto:privacy@eleni.finance" target="_blank">privacy@eleni.finance</a></p>
        <p><b>Address:</b> Tamzen Technology Limited - Company No. 14821672<br/>71-75 Shelton Street<br/>Covent Garden<br/>London WC2H 9JQ<br/>United Kingdom</p>
    </div>
`;

const termsContent = `
    <div>
      <h1>Terms of Use</h1>
      <p class="terms-privacy-page-date">Last updated Last updated September 30, 2024</p>
      <div>
        <p>
            Welcome to <b>app.eleni.finance</b> ("the Service"). By accessing and using our Service, you agree to comply with these Terms of Use. Please read them carefully before proceeding.
        </p>
    </div>
    <div>
      <h4>1. Acceptance of Terms</h4>
      <p>By accessing, browsing, and/or using the Service, you ("the user") agree to be bound by these Terms of Use, as well as any additional terms, conditions, and notices provided within the Service. If you do not agree, please refrain from using the Service.</p>
    </div>
      <h4>2. Collection and Use of Financial Data</h4>
      <p>As part of our Service, we collect and store certain financial data with your explicit consent. The financial information we collect via Salt Edge includes, but is not limited to, transaction data, bank account balances, and account details. We process and store this data solely for the purposes of providing and improving our Service, and in accordance with applicable UK data protection laws (GDPR and UK GDPR). Your financial data will not be shared with third parties without your consent, except as required by law or outlined in our Privacy Policy.</p>
    </div>
    <div>
      <h4>3. User Responsibilities</h4>
      <p>You are responsible for ensuring that the information provided through your use of the Service, including registration and linked financial accounts, is accurate, complete, and up to date. You must also maintain the confidentiality of your login credentials and are responsible for all activities that occur under your account.</p>
      <p>We are committed to safeguarding your personal and financial data by implementing robust security measures. However, you also share the responsibility of using the Service within a secure environment. This includes using strong, unique passwords and ensuring that your device is secure when accessing our platform. We strive to protect your data, but you must promptly notify us of any suspected unauthorized access to your account or any security breaches.</p>
    </div>
    <div>
      <h4>4. Consent for Data Processing</h4>
      <p>By using the Service, you consent to our collection, use, and storage of your financial and personal data as outlined in our Privacy Policy. You may withdraw your consent at any time; however, this may affect your ability to use certain features of the Service.</p>
    </div>
    <div>
      <h4>5. Third-Party Services</h4>
      <p>The Service may include links to or use of third-party services such as Salt Edge. We do not control these third-party services and are not responsible for their practices. We encourage you to review the terms and policies of these third-party providers to understand how they manage your data, including the terms provided by Salt Edge.</p>
    </div>
    <div>
      <h4>6. Security</h4>
      <p>We take reasonable precautions to protect your personal and financial information from unauthorized access or disclosure. However, no system is completely secure, and we cannot guarantee absolute security. You acknowledge that you use the Service at your own risk and that we are not responsible for any unauthorized access to your data due to security breaches.</p>
    </div>
    <div>
      <h4>7. Data Retention</h4>
      <p>We will retain your financial and personal information for as long as necessary to fulfill the purposes for which it was collected, or as required by applicable law. If you deactivate your account or request deletion of your data, we will follow appropriate procedures to remove your data from our active systems in accordance with legal obligations.</p>
    </div>
    <div>
      <h4>8. Use of Salt Edge as Open Banking Provider </h4>
      <p>In order to access your payment accounts in the United Kingdom to provide you with account information services, Tamzen Technology Ltd. has partnered with Salt Edge Limited and, for these purposes, acts as their agent registered by the Financial Conduct Authority (Firm Reference Number 1017899). Salt Edge Limited is registered by the Financial Conduct Authority (Firm Reference Number: 822499) under the Payment Services Regulations 2017 and provides you with the regulated account information services subject to Salt Edge’s own terms. By accessing and using account information services hereunder you agree to <a href="https://www.saltedge.com/legal/delegated_ais_uk_terms" target="_blank">Salt Edge’s Terms of Delegated Account Information Services</a> and <a href="https://www.saltedge.com/legal/delegated_ais_uk_privacy_policy" target="_blank">Delegated Account Information Services: Privacy and Cookies Notice</a>.</p>
      <p>Please note that some of the data provided via Salt Edge is subject to their own terms and conditions. By using our Service, you also agree to Salt Edge’s terms where applicable.</p>
    </div>
    <div>
    <div>
      <h4>9. Limitation of Liability</h4>
      <p>To the fullest extent permitted by law, <b>app.eleni.finance</b> and its affiliates will not be liable for any direct, indirect, incidental, or consequential damages arising from your use of the Service or any third-party services, including Salt Edge, whether based on contract, tort, or any other legal theory.</p>
    </div>
    <div>
      <h4>10. Changes to the Terms</h4>
      <p>We may modify these Terms of Use from time to time to reflect changes in our operations or legal requirements. The revised terms will become effective upon posting. We encourage you to review these terms regularly to stay informed of any updates.</p>
    </div>
    <div>
      <h4>11. Governing Law</h4>
      <p>These Terms of Use shall be governed by and construed in accordance with the laws of England and Wales. Any disputes arising from or relating to these Terms shall be subject to the exclusive jurisdiction of the courts of England.</p>
    </div>
    <div>
      <h4>12. Contact Us</h4>
      <p>If you have any questions, concerns, or requests related to this Privacy Policy or your personal data, please contact us at:</p>
      <p><b>Email:</b> <a href="mailto:privacy@eleni.finance" target="_blank">privacy@eleni.finance</a></p>
      <p><b>Address:</b> Tamzen Technology Limited - Company No. 14821672<br/>71-75 Shelton Street<br/>Covent Garden<br/>London WC2H 9JQ<br/>United Kingdom</p>
    </div>
  `;