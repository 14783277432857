import { Alert, Box, Button, Checkbox, CircularProgress, Container, Dialog, DialogActions, DialogContent, FormControlLabel, FormGroup, IconButton, Stack, Tab, Table, TableBody, TableCell, TableFooter, TableHead, TablePagination, TableRow, Tabs, Tooltip, Typography, useTheme } from "@mui/material";
import { TransactionsProvider, useTransactionsProvider } from "src/hooks/useTransactionsProvider";
import { Currencies, OrdersMappingFields, TransactionsMappingFields, TransactionsProviderFilter } from "src/shared/enums";
import ImportDrawer from "../components/import.drawer";
import { arrayToObject, formatLabel, removeUnderscore } from "src/shared/utils";
import variables from 'src/styles/variables.scss';
import { useSelector } from "react-redux";
import ImportHistory from "../components/import.history";
import { useAuth } from "src/hooks/useAuth";
import { useEffect, useState } from "react";
import AppDrawer from "src/components/AppDrawer";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import CustomSearchBar from "src/components/CustomSearchBar";
import { useSnackbar } from "src/hooks/useSnackbar";

export function AdminUserPage() {
    const { user } = useAuth();
    const settings = arrayToObject(useSelector(state => state.impersonated_settings));
    const userId = settings.currentUser?.id ?? user.username;

    return (
        <Container sx={{ maxWidth: '100% !important', padding: '0 !important' }}>
            <Typography sx={{ mb: '1rem', fontSize: '12px', fontWeight: 500, }}>
                User account data
            </Typography>
            <AdminUserDetails user={userId}></AdminUserDetails>
        </Container>
    )
}

function AdminUserDetails({ user }) {
    const [value, setValue] = useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <>
            {/* <Stack direction={"row"} mb={3} alignItems={"center"} justifyContent={"start"} spacing={3}>
                <Tabs
                    value={value}
                    onChange={handleChange}
                    sx={{
                        border: '1px solid ' + variables.borderGray,
                        borderRadius: '4px',
                        backgroundColor: variables.darkGraySecondary,
                        minHeight: '40px',
                        '.MuiTabs-flexContainer': {
                            height: '40px',

                            '.MuiTab-root': {
                                minHeight: '40px',
                                color: variables.lightGrayPrimary,
                                fontWeight: '400',
                                fontSize: '12px',
                                ':hover': {
                                    backgroundColor: variables.greenBackground,
                                },
                            },
                            '.Mui-selected': {
                                backgroundColor: variables.darkGrayPrimary,
                                color: variables.greenPrimary,
                            }
                        },
                        '.MuiTabs-indicator': {
                            display: 'none',
                        }
                    }}
                >
                    <Tab label="Transactions" />
                    <Tab label="Orders" />
                </Tabs>
                <Box>
                    <ImportHistory user={user}></ImportHistory>
                    <ImportDrawer user={user}></ImportDrawer>
                </Box>
            </Stack> */}
            {
                value === 0 &&
                <TransactionsProvider
                    selectedUser={user}
                    filterConfig={{
                        [TransactionsProviderFilter.transaction]: {},
                        [TransactionsProviderFilter.limit]: {},
                    }}
                >
                    <ObjectsTable></ObjectsTable>
                </TransactionsProvider>
            }

            {
                value === 1 &&
                <TransactionsProvider
                    user={user}
                    object="orders"
                    filterConfig={{
                        [TransactionsProviderFilter.limit]: {}
                    }}
                >
                    <ObjectsTable></ObjectsTable>
                </TransactionsProvider>
            }
        </>
    );
}

const ObjectTableCellStyle = (theme, header = false) => {
    return {
        border: 1,
        borderColor: theme.palette.background.paper,
        background: theme.palette.background[header ? "dark" : "paper"],
        color: "white",
        padding: 1
    }
}

const CheckboxStyle = () => {
    return {
        color: '#3C4251',
        padding: 0,
        margin: '0.5rem',
        '&.MuiCheckbox-root.Mui-checked': {
            color: variables.bluePrimary,
        },
        '&.MuiCheckbox-indeterminate': {
            color: variables.bluePrimary,
        }
    }
}

const ObjectsTable = () => {
    const theme = useTheme();
    const dataProvider = useTransactionsProvider();

    const [selected, setSelected] = useState([]);
    const [openDialog, setOpenDialog] = useState(false);
    const [categoryDrawerOpen, setCategoryDrawerOpen] = useState(false);
    const objects = dataProvider?.data ?? [];

    const mapper = dataProvider.filter.object == "transactions" ? TransactionsMappingFields : OrdersMappingFields;

    const settings = arrayToObject(useSelector(state => state.impersonated_settings));
    const userId = settings.currentUser?.id ?? null;

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = objects.map((n) => n.id);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    // Handle individual row selection
    const handleClickCheckbox = (id) => {
        const selectedIndex = selected.indexOf(id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }

        setSelected(newSelected);
    };

    const isSelected = (id) => selected.indexOf(id) !== -1;

    const handleConfirmDeletion = async () => {
        console.log('Confirmed deletion of items:', selected);
        await dataProvider.deleteData(selected)
        setSelected([]);
        setOpenDialog(false);
    };

    const handleCancelDeletion = () => {
        setSelected([]);
        setOpenDialog(false);
    };

    const handleCategoryClick = ({ object }) => {
        setCategoryDrawerOpen(true);
        setSelected([object.id]);
    }

    return (
        <>
            <Table>
                <TableHead>
                    <TableRow>
                        {selected.length > 0 ? (
                            <>
                                <TableCell sx={{ ...ObjectTableCellStyle(theme, true), padding: 0, textAlign: 'center', width: '40px' }}>
                                    <Checkbox
                                        sx={CheckboxStyle}
                                        indeterminate={selected.length > 0 && selected.length < objects.length}
                                        checked={objects.length > 0 && selected.length === objects.length}
                                        onChange={handleSelectAllClick}
                                        inputProps={{ 'aria-label': 'select all transactions' }}
                                    />
                                </TableCell>
                                <TableCell colSpan={mapper.length} sx={{ ...ObjectTableCellStyle(theme, true), padding: '2px' }}>
                                    <Typography component="span" sx={{ mx: 2, fontSize: '12px' }}>
                                        With selected:
                                    </Typography>
                                    <Tooltip title={"Change transaction category"}>
                                        <Button
                                            variant="outlined"
                                            onClick={() => setCategoryDrawerOpen(true)}
                                            sx={{
                                                height: '30px',
                                                color: variables.lightGrayPrimary,
                                                fontSize: '12px',
                                                borderColor: 'black',
                                                '&:hover': {
                                                    borderColor: 'black',
                                                    color: variables.bluePrimary,
                                                }
                                            }}>
                                            Change category <img src="/assets/icons/ic_category_tag.svg" alt="category icon" style={{ margin: '0 0 1px 8px' }} />
                                        </Button>
                                    </Tooltip>
                                    {/* <Tooltip title={"Delete selected records"}>
                                        <Button
                                            variant="outlined"
                                            onClick={() => setOpenDialog(true)}
                                            sx={{
                                                height: '30px',
                                                color: variables.lightGrayPrimary,
                                                fontSize: '12px',
                                                borderColor: 'black',
                                                '&:hover': {
                                                    borderColor: 'black',
                                                    color: variables.redPrimary,
                                                }
                                            }}>
                                            Delete <img src="/assets/icons/ic_delete.svg" alt="delete icon" style={{ margin: '0 0 1px 8px' }} />
                                        </Button>
                                    </Tooltip> */}
                                </TableCell>
                            </>
                        ) : (
                            <>
                                {objects.length > 0 &&
                                    <TableCell sx={{ ...ObjectTableCellStyle(theme, true), padding: 0, textAlign: 'center', width: '40px' }}>
                                        <Checkbox
                                            sx={CheckboxStyle}
                                            indeterminate={selected.length > 0 && selected.length < objects.length}
                                            checked={objects.length > 0 && selected.length === objects.length}
                                            onChange={handleSelectAllClick}
                                            inputProps={{ 'aria-label': 'select all transactions' }}
                                        />
                                    </TableCell>
                                }
                                {mapper.map((field, index) => {
                                    const fieldName = removeUnderscore(field.replace("transaction_", ""))
                                    return (
                                        <TableCell key={field} sx={ObjectTableCellStyle(theme, true)}>
                                            {fieldName}
                                        </TableCell>
                                    );
                                })
                                }
                            </>
                        )}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        (dataProvider?.loading == null || dataProvider?.loading == true && objects.length == 0) && <Typography>Loading...</Typography>
                    }
                    {dataProvider?.loading !== true && objects?.map((object) => {
                        const isItemSelected = isSelected(object.id);

                        return (
                            <TableRow
                                key={object.id}
                                hover
                                role="checkbox"
                                aria-checked={isItemSelected}
                                selected={isItemSelected}
                                sx={{
                                    '&:hover .categoryIcon': {
                                        visibility: 'visible',
                                        opacity: 1,
                                    }
                                }}
                            >
                                <TableCell sx={{ ...ObjectTableCellStyle(theme), backgroundColor: "inherit", padding: 0, textAlign: 'center', width: '40px' }}>
                                    <Checkbox
                                        sx={CheckboxStyle}
                                        checked={isItemSelected}
                                        inputProps={{ 'aria-labelledby': `enhanced-table-checkbox-${object.id}` }}
                                        onClick={() => handleClickCheckbox(object.id)}
                                    />
                                </TableCell>
                                {

                                    mapper.map((field) => {
                                        let fieldValue = object[field] ?? "-";

                                        if (field === "category_key") {
                                            fieldValue = removeUnderscore(object[field] ?? "_");
                                            return (
                                                <TableCell key={field} sx={{ ...ObjectTableCellStyle(theme), backgroundColor: "inherit" }}>
                                                    <Typography variant="body2" sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                                        {fieldValue}
                                                        <IconButton
                                                            className="categoryIcon"
                                                            onClick={() => handleCategoryClick({ object })}
                                                            size="small"
                                                            sx={{ marginLeft: 1, visibility: 'hidden', opacity: 0 }}
                                                        >
                                                            <img src="/assets/icons/ic_category_tag.svg" alt="category icon" style={{ margin: '0 0 1px 8px' }} />
                                                        </IconButton>
                                                    </Typography>
                                                </TableCell>
                                            );
                                        }

                                        if (field == "currency")
                                            fieldValue = Currencies[object[field]] ?? "-";

                                        if (field == "amount")
                                            fieldValue = parseFloat(fieldValue).toLocaleString('en-US');

                                        return (
                                            <TableCell key={field} sx={{ ...ObjectTableCellStyle(theme), backgroundColor: "inherit" }}>
                                                <Typography variant="body2">
                                                    {fieldValue}
                                                </Typography>
                                            </TableCell>
                                        );
                                    })
                                }
                            </TableRow>
                        );
                    })
                    }

                </TableBody>
                <TableFooter>
                    <TableRow>
                        <TablePagination
                            sx={{ border: 0 }}
                            rowsPerPageOptions={[]}
                            count={-1}
                            rowsPerPage={dataProvider?.filter?.limit ?? 10}
                            page={dataProvider?.filter?.page ?? 0}
                            onPageChange={(event, newPage) => {
                                dataProvider.setFilter({ ...dataProvider.filter, page: newPage })
                            }}
                            onRowsPerPageChange={(event) => {
                                dataProvider.setFilter({ ...dataProvider.filter, limit: parseInt(event.target.value, 10) })
                            }}
                            nextIconButtonProps={{
                                disabled: objects.length < (dataProvider?.filter?.limit ?? 10) // Disable if less than limit
                            }}
                            labelDisplayedRows={({ from, to }) => `${from} - ${to} transactions`}
                        />
                    </TableRow>
                </TableFooter>
            </Table>
            {objects.length == 0 && dataProvider?.loading !== true && (dataProvider.filter.category_key != null || dataProvider.filter.start != null || dataProvider.filter.minAmount != null) &&
                <Typography sx={{ fontSize: '14px', color: variables.lightGraySecondary, p: '1rem' }}>No transactions found for the selected filters.</Typography>
            }
            <Dialog
                open={openDialog}
                onClose={handleCancelDeletion}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                sx={{
                    '.MuiDialog-paper': {
                        height: '525px',
                        p: '5rem'
                    },
                }}
            >
                <Typography sx={{ display: 'flex', justifyContent: 'center', flexFlow: 'column', mb: '2.5rem', alignItems: 'center', fontSize: '20px', fontWeight: '500', }}>
                    <img src="/assets/icons/ic_alert_deletion.svg" alt="alert deletion icon" style={{ width: '40px', height: '40px', marginBottom: '1.5rem' }} />
                    {`Delete ${selected.length} records?`}
                </Typography>
                <DialogContent>
                    <Typography id="alert-dialog-description" sx={{ fontSize: '14px', mb: '2rem', textAlign: 'center' }}>
                        You are about to delete {selected.length} records from your transactions database. <b>This action cannot be undone.</b>
                    </Typography>
                    <Typography id="alert-dialog-description" sx={{ fontSize: '14px', textAlign: 'center' }}>
                        Are you sure you want to proceed?
                    </Typography>
                </DialogContent>
                <DialogActions sx={{ justifyContent: 'space-between' }}>
                    <Button
                        onClick={handleCancelDeletion}
                        sx={{
                            color: variables.lightGrayPrimary,
                            fontWeight: 500,
                        }}
                    >
                        Cancel deletion
                    </Button>
                    <Button
                        variant="contained"
                        onClick={handleConfirmDeletion}
                        autoFocus
                        sx={{
                            boxShadow: 'none',
                            fontWeight: 500,
                            background: variables.redPrimary,
                            '&:hover': {
                                background: variables.redPrimary,
                                color: 'black',
                            }
                        }}
                    >
                        Confirm & Delete Records
                    </Button>
                </DialogActions>
            </Dialog>

            {categoryDrawerOpen &&
                <CategoryDrawer
                    setOpen={setCategoryDrawerOpen}
                    setSelected={setSelected}
                    open={categoryDrawerOpen}
                    item={selected}
                    details={selected.length == 1 ? objects.find(obj => obj.id === selected[0]) : selected.map(id => objects.find(obj => obj.id === id))}
                />
            }
        </>
    );
}

const CategoryDrawer = ({ setOpen, setSelected, open, item, details = null }) => {
    const snackbar = useSnackbar();
    const dataProvider = useTransactionsProvider();

    const titleStyle = {
        fontSize: '12px',
        color: variables.midGraySecondary,
        fontWeight: 600,
        marginBottom: '4px',
    }

    const detailsStyle = {
        fontSize: '14px',
        color: variables.lightGrayPrimary,
    }

    const detailsItemStyle = {
        marginBottom: '1rem',
    }

    const [showCategorySelector, setShowCategorySelector] = useState(false);
    const [selectedCategory, setSelectedCategory] = useState(details?.category_key);
    const [alertOpen, setAlertOpen] = useState(false);
    const [loading, setLoading] = useState(false);

    const categories = {
        "income": [
            {
                "category_key": "uncategorized",
                "parent_key": null
            },
            {
                "category_key": "saltedge",
                "parent_key": null
            },
            {
                "category_key": "forecasted",
                "parent_key": null
            },
            {
                "category_key": "stripe",
                "parent_key": null
            }
        ],
        "expense": [
            {
                "category_key": "uncategorized",
                "parent_key": null
            },
            {
                "category_key": "operating_expenses",
                "parent_key": null
            },
            {
                "category_key": "investments",
                "parent_key": null
            },
            {
                "category_key": "borrowing",
                "parent_key": null
            },
            {
                "category_key": "taxes",
                "parent_key": null
            },
            {
                "category_key": "cost_of_sales",
                "parent_key": null
            }
        ]
    };

    console.log(item);

    const handleSaveCategory = async (transactions, newCategory) => {
        setLoading(true);
        setSelectedCategory(newCategory);
        setShowCategorySelector(false);

        console.log(transactions);
        const ids = transactions.map(obj => obj.id);
        const oldCategory = transactions.map(t => `${t.id}:${t.category_key}`).join(',');
        const result = await dataProvider.updateData({ ids: ids.join(','), field: 'category_key', old_value: oldCategory, new_value: newCategory });
        console.log(result)
        if (result) {
            setLoading(false);
            setAlertOpen(true);

            if (item.length > 1) {
                setTimeout(() => {
                    snackbar.openSuccess(`Category changed successfully for ${transactions.length} transactions`);
                    setSelected([]);
                    setOpen(false);
                }, 1000);
            }
        }
    }

    return (
        <AppDrawer
            anchor="right"
            open={open}
            onClose={() => { setOpen(false); setSelected([]) }}
            width={100}
            height={92}
            title={item.length === 1 ? "Transaction details" : `Change category for ${item.length} transactions`}
        >
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    height: '100%',
                }}
            >
                <div style={{ marginTop: '20px', width: '550px', height: item.length > 1 ? '100%' : '' }}>
                    {item.length === 1 &&
                        <>
                            {alertOpen &&
                                <Alert severity="success" variant="outlined" sx={{ marginBottom: '1rem', background: variables.lightGrayPrimary, height: '40px', padding: '0px 8px' }}>
                                    Category changed successfully
                                </Alert>
                            }
                            <Stack direction={'row'}>
                                <Box style={{ marginRight: '4rem', ...detailsItemStyle }}>
                                    <Typography sx={titleStyle}>ID</Typography>
                                    <Box style={{ display: 'flex' }}>
                                        <Typography sx={detailsStyle}>{details?.transaction_id}</Typography>
                                        <Tooltip title="Copy transaction ID">
                                            <ContentCopyIcon
                                                onClick={() => {
                                                    navigator.clipboard.writeText(details?.transaction_id);
                                                    snackbar.openSuccess("Transaction ID copied to clipboard");
                                                }}
                                                sx={{
                                                    cursor: 'pointer',
                                                    color: variables.lightGraySecondary,
                                                    width: '18px',
                                                    margin: '0 0 0 0.5rem',
                                                }}
                                            />
                                        </Tooltip>
                                    </Box>
                                </Box>
                                <Box sx={detailsItemStyle}>
                                    <Typography sx={titleStyle}>Date</Typography>
                                    <Typography sx={detailsStyle}>{details?.date}</Typography>
                                </Box>
                            </Stack>

                            <Typography sx={titleStyle}>Category</Typography>
                        </>
                    }
                    {(!showCategorySelector && item.length === 1) &&
                        <>
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    border: '1px solid ' + variables.midGrayPrimary,
                                    borderRadius: '8px',
                                    padding: '0.5rem',
                                    marginBottom: '1rem',
                                    cursor: 'pointer',
                                }}
                                onClick={() => setShowCategorySelector(true)}
                            >
                                <Typography sx={detailsStyle}>{formatLabel(selectedCategory)}</Typography>
                                <KeyboardArrowDownIcon sx={{ color: variables.bluePrimary, fontSize: '20px' }} />
                            </Box>
                            {loading &&
                                <Stack direction={'row'} alignItems={'center'} sx={{ pb: '1rem' }}>
                                    <CircularProgress sx={{ color: variables.orangePrimary, margin: '4px' }} size={15} />
                                    <Typography sx={{ fontSize: '12px', color: variables.orangePrimary }}>Saving, changes, please wait</Typography>
                                </Stack>
                            }
                            <Box sx={detailsItemStyle}>
                                <Typography sx={titleStyle}>Counterparty</Typography>
                                <Typography sx={detailsStyle}>{details?.counterparty ?? '-'}</Typography>
                            </Box>
                            <Box sx={detailsItemStyle}>
                                <Typography sx={titleStyle}>Account</Typography>
                                <Typography sx={detailsStyle}>{details?.account}</Typography>
                            </Box>
                            <Stack direction={'row'}>
                                <Box style={{ marginRight: '4rem', ...detailsItemStyle }}>
                                    <Typography sx={titleStyle}>Currency</Typography>
                                    <Typography sx={detailsStyle}>{details?.currency.toUpperCase()}</Typography>
                                </Box>
                                <Box sx={detailsItemStyle}>
                                    <Typography sx={titleStyle}>Amount</Typography>
                                    <Typography sx={detailsStyle}>{details?.amount}</Typography>
                                </Box>
                            </Stack>
                        </>
                    }
                    {(showCategorySelector || item.length > 1) && (
                        <CategorySelector
                            categories={categories}
                            defaultCategory={details?.category_key}
                            amounts={details?.amount ?? details?.map(obj => obj.amount)}
                            transactions={details}
                            multiple={item.length > 1}
                            onClose={() => { if (item.length === 1) { setShowCategorySelector(false) } else { setOpen(false); setSelected([]) } }}
                            onSave={(transactions, newCategory) => handleSaveCategory(transactions, newCategory)}
                            loading={loading}
                        />
                    )}
                </div>
                {item.length === 1 &&
                    <Box sx={{ display: 'flex', justifyContent: 'end' }}>
                        <Button onClick={() => { setOpen(false); setSelected([]) }} sx={{ color: variables.midGraySecondary }}>Close</Button>
                    </Box>
                }
            </Box>
        </AppDrawer>
    )
}

const CategorySelector = ({ categories, onClose, onSave, defaultCategory, amounts, transactions, multiple, loading }) => {
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedCategory, setSelectedCategory] = useState(defaultCategory);
    const [displayedCategories, setDisplayedCategories] = useState(new Set());
    const [alertMessage, setAlertMessage] = useState('');

    // Determine count of positive and negative transactions
    const positiveCount = amounts.length > 0 ? amounts.filter(amount => amount >= 0).length : 0;
    const negativeCount = amounts.length > 0 ? amounts.filter(amount => amount < 0).length : 0;

    // Determine if there are both positive and negative amounts
    const hasPositive = amounts.length > 0 ? amounts.some(amount => amount >= 0) : false;
    const hasNegative = amounts.length > 0 ? amounts.some(amount => amount < 0) : false;

    const findCategoryType = (categoryKey) => {
        const isInIncome = categories.income.some(cat => cat.category_key === categoryKey);
        const isInExpense = categories.expense.some(cat => cat.category_key === categoryKey);
        return isInIncome ? 'income' : isInExpense ? 'expense' : null;
    };

    const onSaveFiltered = () => {
        if (multiple) {
            const applicableTransactions = transactions.filter(t =>
                (findCategoryType(selectedCategory) === 'income' && t.amount >= 0) ||
                (findCategoryType(selectedCategory) === 'expense' && t.amount < 0)
            ).map(t => ({ id: t.id, category_key: t.category_key }));
            onSave(applicableTransactions, selectedCategory);
        } else {
            onSave([{ id: transactions.id, category_key: transactions.category_key }], selectedCategory);
        }
    };

    useEffect(() => {
        const newDisplayedCategories = new Set();
        Object.entries(categories).forEach(([parentKey, childCats]) => {
            if (childCats.some(cat => cat.category_key === selectedCategory)) {
                newDisplayedCategories.add(parentKey);
            }
        });
        setDisplayedCategories(newDisplayedCategories);

        if (multiple) {
            if (findCategoryType(selectedCategory) === 'income') {
                if (positiveCount !== 0 && negativeCount < amounts.length) {
                    setAlertMessage(`Changes will be applied to ${positiveCount} of ${amounts.length} selected transactions.`);
                }
            } else if (findCategoryType(selectedCategory) === 'expense') {
                if (negativeCount !== 0 && negativeCount < amounts.length) {
                    setAlertMessage(`Changes will be applied to ${negativeCount} of ${amounts.length} selected transactions.`);
                }
            } else {
                setAlertMessage('');
            }
        }
    }, [selectedCategory, categories]);

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value.toLowerCase());
    };

    const handleCheckboxChange = (category_key) => {
        setSelectedCategory(category_key);
    };



    const isCategoryChecked = (category_key, parent_key = null) => {
        return category_key === selectedCategory;
    };

    const filteredCategories = Object.keys(categories).reduce((acc, type) => {
        const shouldIncludeType = multiple ? (hasPositive && hasNegative) : false;
        if (shouldIncludeType && multiple) {
            return categories;
        }
        const typeCategories = (amounts >= 0 || hasPositive) ? 'income' : 'expense';
        const matchingChildren = categories[typeCategories].filter(cat => cat.category_key.toLowerCase().includes(searchTerm));
        if ((typeCategories.toLowerCase().includes(searchTerm) || matchingChildren.length > 0)) {
            acc[typeCategories] = matchingChildren;
        }
        return acc;
    }, {});

    return (
        <Box
            sx={{
                padding: 2,
                mb: '0.5rem',
                height: '100%',
                background: multiple ? 'transparent' : variables.darkGrayPopover,
                border: multiple ? 'none' : '1px solid ' + variables.midGrayPrimary,
                borderRadius: '4px',
                justifyContent: 'space-between',
                display: 'flex',
                flexFlow: 'column',
            }}
        >
            <Box>
                <CustomSearchBar
                    searchQuery={searchTerm}
                    onSearchChange={handleSearchChange}
                    placeholder="Search for category..."
                ></CustomSearchBar>
                {Object.entries(filteredCategories).map(([type, cats]) => (
                    <FormGroup key={type}>
                        <FormControlLabel
                            control={<Checkbox
                                sx={CheckboxStyle}
                                checked={displayedCategories.has(type)}
                                onChange={() => { }}  // No-op for parent categories
                            />}
                            label={formatLabel(type)}
                            key={type}
                            sx={{ backgroundColor: displayedCategories.has(type) ? variables.darkGrayPrimary : 'transparent' }}
                        />
                        {cats.map(cat => (
                            <FormControlLabel
                                control={<Checkbox
                                    checked={isCategoryChecked(cat.category_key, type)}
                                    onChange={() => handleCheckboxChange(cat.category_key)}
                                    sx={CheckboxStyle}
                                />}
                                label={formatLabel(cat.category_key)}
                                key={cat.category_key}
                                sx={{ mx: '1rem', backgroundColor: isCategoryChecked(cat.category_key, type) ? variables.darkGrayPrimary : 'transparent' }}
                            />
                        ))}
                    </FormGroup>
                ))}
            </Box>
            <Box>
                {alertMessage &&
                    <Alert severity="warning" variant={'outlined'} sx={{ fontSize: '12px', color: variables.lightGrayPrimary }}>
                        {alertMessage + ' '}
                        <Tooltip
                            title="The chosen categories don't apply to all the selected transactions. A common reason for this is that you have selected both income and expenses transactions"
                        >
                            <span style={{ cursor: 'pointer', textDecoration: 'underline', fontSize: '12px' }}>Why is this?</span>
                        </Tooltip>
                    </Alert>
                }
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'end',
                        marginTop: 2,
                        alignItems: 'center',
                    }}
                >
                    {loading &&
                        <Stack direction={'row'} alignItems={'center'} sx={{ width: '100%' }}>
                            <CircularProgress sx={{ color: variables.orangePrimary, margin: '4px' }} size={15} />
                            <Typography sx={{ fontSize: '12px', color: variables.orangePrimary }}>Saving, changes, please wait</Typography>
                        </Stack>
                    }
                    <Button
                        sx={{
                            color: variables.midGraySecondary,
                            '&:hover': {
                                color: variables.bluePrimary,
                                backgroundColor: 'transparent',
                            },
                            mr: '0.5rem'
                        }}
                        onClick={onClose}
                    >
                        Cancel
                    </Button>
                    <Button
                        variant="contained"
                        sx={{
                            backgroundColor: variables.bluePrimary,
                            color: 'black',
                            boxShadow: 'none',
                            width: '40%',
                            borderRadius: '4px',

                        }}
                        onClick={onSaveFiltered}
                    >
                        Save Changes
                    </Button>
                </Box>
            </Box>
        </Box>
    );
};
