import { useEffect, useState } from 'react';
import { Box, Divider, Typography, MenuItem, Avatar, Popover } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import { useAuth } from 'src/hooks/useAuth';
import { arrayToObject } from 'src/shared/utils';
import { useNavigate } from 'react-router-dom';
import variables from 'src/styles/variables.scss';
import { useSelector } from 'react-redux';
import { ComingSoon } from 'src/utils/utils';


const _url = "https://ui-avatars.com/api/?name=";

export default function AccountPopover() {
  const navigate = useNavigate();

  const [open, setOpen] = useState(null);
  const { user, logout } = useAuth();
  const globalSettings = arrayToObject(useSelector(state => state.settings));
  const impersonatedSettings = arrayToObject(useSelector(state => state.impersonated_settings));
  const [fullName, setFullName] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      if (user.isAdmin && impersonatedSettings?.currentUser) {
        const profile = impersonatedSettings?.profile;
        setFullName(profile?.firstName + " " + profile?.lastName);
      } else {
        setFullName(globalSettings?.profile?.firstName + " " + globalSettings?.profile?.lastName);
      }
    };

    fetchData();
    // eslint-disable-next-line
  }, []);

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const handleNavigation = (path) => {
    navigate(path);
    setOpen(null);
  }

  return (
    <>
      <Avatar src={`${_url}${fullName}`} alt="photoURL" />
      <Typography
        onClick={handleOpen}
        sx={{
          p: 0,
          cursor: 'pointer',
          fontSize: '12px',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <KeyboardArrowDownIcon />
      </Typography>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 0,
            mt: 1.5,
            ml: 0.75,
            width: 200,
            background: variables.darkGraySecondary,
            '& .MuiMenuItem-root': {
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        <Box sx={{ my: 1.5, px: 0.5 }}>
          <Typography variant="subtitle2" sx={{ px: '1rem', }} noWrap>
            {fullName}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary', px: '1rem', }} noWrap>
            {user.isAdmin ? impersonatedSettings?.currentUser?.email ?? user.attributes.email : user.attributes.email}
          </Typography>
        </Box>
        <Divider />
        <MenuItem onClick={() => handleNavigation("/profile-settings")}>
          Profile
        </MenuItem>
        <MenuItem onClick={() => handleNavigation("/security-settings")}>
          Security and privacy
        </MenuItem>
        <MenuItem disabled onClick={() => handleNavigation("/account/help")} sx={{ justifyContent: 'space-between' }}>
          Help & FAQ
          <ComingSoon></ComingSoon>
        </MenuItem>
        <MenuItem onClick={() => handleNavigation("/terms-and-privacy")}>
          Terms & Privacy
        </MenuItem>
        <Divider />
        <MenuItem onClick={logout} sx={{ mb: '0.5rem', color: variables.redPrimary }}>
          Sign Out
        </MenuItem>
      </Popover>
    </>
  );
}
